import React, { useCallback, useContext, useEffect, useState } from 'react';
import { isEqual } from 'lodash';
import { PaddedPage } from '../../styling/layout/PaddedPage';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { allNonAdminUserRoles } from '../authentication/UserRole';
import { RequiresUserRole } from '../authentication/UserRoles';
import { useGetJson } from '../../infrastructure/api/useGetJson';
import { useWindowTitle } from '../../infrastructure/hooks/useWindowTitle';
import {
  FundExpandedState,
  GetDataForHoldingsQuery,
  GetDataForHoldingsResponse,
  HoldingsFilterState,
} from './types';
import { RequestFailedPage } from '../../infrastructure/api/RequestFailedPage';
import { useUrlState } from '../../infrastructure/hooks/useUrlState';
import { defaultHoldingsFilterState } from './HoldingsContext';
import { getIsoDatestampToday, IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { DesktopOnly } from '../../styling/layout/DesktopOnly';
import { DesktopHoldings } from './DesktopHoldings';
import { MobileOnly } from '../../styling/layout/MobileOnly';
import { MobileHoldings } from './MobileHoldings';
import { HoldingsContext } from './HoldingsContext';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { useSessionCompanyId } from '../../infrastructure/hooks/useSessionCompanyId';
import { RequiresMenuOption } from '../authentication/UserMenuOptions';

export const Holdings = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.holdings.title'));

  return (
    <RequiresUserRole userRole={allNonAdminUserRoles}>
      <RequiresMenuOption menuOption={'holdings'}>
        <HoldingsComponent />
      </RequiresMenuOption>
    </RequiresUserRole>
  );
};

const HoldingsComponent = () => {
  const authenticationContext = useContext(AuthenticationContext);
  const user = authenticationContext.getUser();

  const getRequest = useGetJson<GetDataForHoldingsQuery, GetDataForHoldingsResponse>(
    '/api/holdings/GetDataForHoldingsPage'
  );

  const [filterValuesAllLoaded, setFilterValuesAllLoaded] = useState<boolean>(false);

  const {
    state: filterState,
    setState: setFilterState,
    updateState,
    useUrlStateValue,
  } = useUrlState<HoldingsFilterState>(defaultHoldingsFilterState, {
    pageNumber: { type: 'number' },
    resultsPerPage: { type: 'number' },
    companyId: { type: 'number', isNullable: true },
    equityAttributionId: { type: 'number', isNullable: true },
    investorId: { type: 'number', isNullable: true },
    agentId: { type: 'number', isNullable: true },
    dealerId: { type: 'number', isNullable: true },
    employeeId: { type: 'number', isNullable: true },
    endDate: { type: 'string', isNullable: true },
  });

  const [fundExpandedStates, setFundExpandedStates] = useState<Array<FundExpandedState>>([]);
  const [pageNumber, setPageNumber] = useUrlStateValue('pageNumber');
  const [filterLoadError, setFilterLoadError] = useState<string | null>(null);

  const resultsPerPage = filterState.resultsPerPage;
  const setResultsPerPage = (resultsPerPage: number) =>
    updateState({ resultsPerPage, pageNumber: 1 });

  const { sessionCompanyId } = useSessionCompanyId();
  const companyId = filterState.companyId ?? sessionCompanyId;
  const setCompanyId = (companyId: number | null) => updateState({ companyId, pageNumber: 1 });
  const [companySelectLoaded, setCompanySelectLoaded] = useState<boolean>(false);

  const equityAttributionId = filterState.equityAttributionId;
  const setEquityAttributionId = (equityAttributionId: number | null) =>
    updateState({ equityAttributionId, pageNumber: 1 });
  const [equityAttributionSelectLoaded, setEquityAttributionSelectLoaded] =
    useState<boolean>(false);

  const investorId = filterState.investorId;
  const setInvestorId = (investorId: number | null) => updateState({ investorId, pageNumber: 1 });
  const [investorSelectLoaded, setInvestorSelectLoaded] = useState<boolean>(false);

  const agentId = filterState.agentId;
  const setAgentId = (agentId: number | null) => updateState({ agentId, pageNumber: 1 });
  const [agentSelectLoaded, setAgentSelectLoaded] = useState<boolean>(false);

  const dealerId = filterState.dealerId;
  const setDealerId = (dealerId: number | null) => updateState({ dealerId, pageNumber: 1 });
  const [dealerSelectLoaded, setDealerSelectLoaded] = useState<boolean>(false);

  const employeeId = filterState.employeeId;
  const setEmployeeId = (employeeId: number | null) => updateState({ employeeId, pageNumber: 1 });
  const [employeeSelectLoaded, setEmployeeSelectLoaded] = useState<boolean>(false);

  const endDate = filterState.endDate;
  const setEndDate = (endDate: IsoDatestamp | null) => updateState({ endDate, pageNumber: 1 });

  const [latestResponse, setLatestResponse] = useState<GetDataForHoldingsResponse | null>(null);

  const resetFilters = () => {
    if (!isEqual(filterState, defaultHoldingsFilterState)) {
      setFilterState(defaultHoldingsFilterState);
      setLatestResponse(null);
    }
  };

  const [showEqualisationAdjustmentMessage, setShowEqualisationAdjustmentMessage] =
    useState<boolean>(false);

  const onGetDataForHoldingsRequestSuccess = (holdingsResponse: GetDataForHoldingsResponse) => {
    setLatestResponse(holdingsResponse);
    setShowEqualisationAdjustmentMessage(holdingsResponse.showEqualisationAdjustmentMessage);

    setFundExpandedStates(
      [...holdingsResponse.traditionalFunds, ...holdingsResponse.privateEquityFunds].map(
        (fund, index) => ({
          id: index,
          expand: false,
        })
      )
    );
  };

  const check = useCallback(() => {
    if (!filterValuesAllLoaded) {
      if (user.role === 'Investor') {
        setFilterValuesAllLoaded(true);
      }

      if (user.role === 'Manager') {
        setFilterValuesAllLoaded(
          companySelectLoaded &&
            equityAttributionSelectLoaded &&
            dealerSelectLoaded &&
            agentSelectLoaded &&
            investorSelectLoaded
        );
      }

      if (user.role === 'Advisor') {
        setFilterValuesAllLoaded(employeeSelectLoaded && investorSelectLoaded);
      }

      if (user.role === 'Consolidated Investor') {
        setFilterValuesAllLoaded(investorSelectLoaded);
      }
    }
  }, [
    agentSelectLoaded,
    companySelectLoaded,
    dealerSelectLoaded,
    employeeSelectLoaded,
    equityAttributionSelectLoaded,
    filterValuesAllLoaded,
    investorSelectLoaded,
    user.role,
  ]);

  const makeRequest = useCallback(
    () => {
      getRequest.makeRequest({
        queryParameters: {
          pageNumber: pageNumber,
          resultsPerPage: resultsPerPage,
          eDate: endDate ?? getIsoDatestampToday(),
          companyId,
          equityAttributionId,
          investorId,
          agentId,
          dealerId,
          employeeId,
        },
        onSuccess: onGetDataForHoldingsRequestSuccess,
      });
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      pageNumber,
      resultsPerPage,
      endDate,
      companyId,
      equityAttributionId,
      investorId,
      agentId,
      dealerId,
      employeeId,
    ]
  );

  useEffect(() => {
    check();

    if (filterValuesAllLoaded) {
      makeRequest();
    }
  }, [check, filterValuesAllLoaded, makeRequest]);

  if (filterLoadError) {
    return <RequestFailedPage error={filterLoadError} />;
  }

  if (getRequest.state.error) {
    return <RequestFailedPage error={getRequest.state.error} retry={makeRequest} />;
  }

  return (
    <PaddedPage>
      <HoldingsContext.Provider
        value={{
          pageNumber,
          setPageNumber,
          resultsPerPage,
          setResultsPerPage,
          companyId,
          setCompanyId,
          companySelectLoaded,
          setCompanySelectLoaded,
          equityAttributionId,
          setEquityAttributionId,
          equityAttributionSelectLoaded,
          setEquityAttributionSelectLoaded,
          investorId,
          setInvestorId,
          investorSelectLoaded,
          setInvestorSelectLoaded,
          agentId,
          setAgentId,
          agentSelectLoaded,
          setAgentSelectLoaded,
          dealerId,
          setDealerId,
          dealerSelectLoaded,
          setDealerSelectLoaded,
          employeeId,
          setEmployeeId,
          employeeSelectLoaded,
          setEmployeeSelectLoaded,
          endDate,
          setEndDate,
          latestResponse,
          inProgress: getRequest.state.inProgress,
          filterLoadError,
          setFilterLoadError,
          fundExpandedStates,
          setFundExpandedStates,
          resetFilters,
          showEqualisationAdjustmentMessage,
          setShowEqualisationAdjustmentMessage,
        }}
      >
        <DesktopOnly>
          <DesktopHoldings />
        </DesktopOnly>
        <MobileOnly>
          <MobileHoldings />
        </MobileOnly>
      </HoldingsContext.Provider>
    </PaddedPage>
  );
};

export const holdingsPageCompanySelectTestId = 'holdings-page-company-select';
export const holdingsPageInvestorSelectTestId = 'holdings-page-investor-select';
export const holdingsPageEquityAttributionSelectTestId = 'holdings-page-equityAttribution-select';
export const holdingsPageAgentSelectTestId = 'holdings-page-agent-select';
export const holdingsPageDealerSelectTestId = 'holdings-page-dealer-select';
export const holdingsPageEmployeeSelectTestId = 'holdings-page-employee-select';
export const holdingsPageEndDatePickerTestId = 'holdings-page-endDate-datepicker';
