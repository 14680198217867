import { assertNotBlank } from './stringHelpers';
import { addDays, formatISO, startOfToday, subDays } from 'date-fns';

export type IsoDatestamp = string; // yyyy-MM-dd

export const getIsoDatestampFromUnknownFormatDateTimeString = (
  unknownFormatDateTimeString: string,
  addDaysIntoFuture?: number,
  subDaysIntoPast?: number
): IsoDatestamp => {
  let date = parseDateFromUnknownFormatDateTimeString(unknownFormatDateTimeString);
  if (addDaysIntoFuture != null) date = addDays(date, addDaysIntoFuture);
  if (subDaysIntoPast != null) date = subDays(date, subDaysIntoPast);
  return toIsoDatestampFromDate(date);
};

export const getUnixEpochIsoDateStamp = () =>
  getIsoDatestampFromUnknownFormatDateTimeString('1970-01-01 00:00:00');

export const toIsoDatestampFromDate = (date: Date): IsoDatestamp =>
  formatISO(date, { representation: 'date' });

export const toDateFromIsoDatestamp = (isoDateStamp: IsoDatestamp): Date =>
  parseDateFromUnknownFormatDateTimeString(isoDateStamp);

export const getIsoDatestampToday = (): IsoDatestamp => toIsoDatestampFromDate(startOfToday());

export const getIsoDatestampDaysIntoThePast = (daysIntoThePast: number): IsoDatestamp =>
  toIsoDatestampFromDate(subDays(startOfToday(), daysIntoThePast));

export const getIsoDatestampDaysIntoTheFuture = (daysIntoTheFuture: number): IsoDatestamp =>
  toIsoDatestampFromDate(addDays(startOfToday(), daysIntoTheFuture));

export const parseDateFromUnknownFormatDateTimeString = (
  unknownFormatDateTimeString: string
): Date => {
  assertNotBlank(unknownFormatDateTimeString);

  const unixTimestamp = Date.parse(unknownFormatDateTimeString);

  if (isNaN(unixTimestamp)) {
    throw new Error(`Failed to parse date from timestamp: ${unknownFormatDateTimeString}`);
  }

  return new Date(unixTimestamp);
};

export const now = () => new Date();
