import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import { PencilIcon } from '../../../icons/icons';
import { ButtonWithIcon } from '../../../infrastructure/interface/buttons/ButtonWithIcon';
import { FieldAndValue } from '../../../infrastructure/interface/components/FieldAndValue';
import {
  ItemDetailsCard,
  ItemDetailsCardButtonsContainer,
  MobileNoResultsCard,
} from '../../../infrastructure/interface/components/ItemDetailsCard';
import { LoadingOverlay } from '../../../infrastructure/interface/components/LoadingOverlay';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DeleteDistributionReinvestmentButton } from './DeleteDistributionReinvestmentButton';
import { useDistributionReinvestmentsContext } from './DistributionReinvestmentsContext';

export const MobileDistributionReinvestmentsList = () => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();

  const {
    latestDistributionReinvestmentsResponse,
    distributionReinvestmentsRequestInProgress,
    permissionsResponse,
  } = useDistributionReinvestmentsContext();

  if (latestDistributionReinvestmentsResponse == null) {
    return null;
  }

  return (
    <LoadingOverlay showOverlay={distributionReinvestmentsRequestInProgress}>
      {isEmpty(latestDistributionReinvestmentsResponse.distributionReinvestments) ? (
        <MobileNoResultsCard
          message={translate('pages.distributionReinvestments.noDistributionReinvestments')}
        />
      ) : (
        latestDistributionReinvestmentsResponse.distributionReinvestments.map(
          (distributionReinvestment, index) => (
            <ItemDetailsCard
              title={distributionReinvestment.fundName}
              key={distributionReinvestment.distributionReinvestmentId}
            >
              <FieldAndValue
                type="date"
                fieldLabel={translate('pages.distributionReinvestments.fieldNames.validFrom')}
                value={distributionReinvestment.validFrom}
              />
              <FieldAndValue
                type="text"
                fieldLabel={translate(
                  'pages.distributionReinvestments.fieldNames.reinvestmentPreference'
                )}
                value={distributionReinvestment.reinvestmentPreference}
              />
              <ItemDetailsCardButtonsContainer>
                {distributionReinvestment.editable && (
                  <ButtonWithIcon
                    icon={<PencilIcon />}
                    buttonStyle="secondary"
                    stretch={true}
                    size="small"
                    onClick={() =>
                      navigate(`${distributionReinvestment.distributionReinvestmentId}/edit`)
                    }
                  >
                    {translate('actionButtons.edit')}
                  </ButtonWithIcon>
                )}
                {permissionsResponse?.canEditDistributionReinvestments === true && (
                  <DeleteDistributionReinvestmentButton
                    distributionReinvestmentId={distributionReinvestment.distributionReinvestmentId}
                    size="small"
                  />
                )}
              </ItemDetailsCardButtonsContainer>
            </ItemDetailsCard>
          )
        )
      )}
    </LoadingOverlay>
  );
};
