import React from 'react';
import { useInvestorBankingDetailsContext } from './InvestorBankingDetailsContext';
import { RequestFailedAlert } from '../../../infrastructure/api/RequestFailedAlert';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { DesktopInvestorBankingDetails } from './DesktopInvestorBankingDetails';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { MobileInvestorBankingDetails } from './MobileInvestorBankingDetails';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { bankingDetailsActionStatusesByCode } from './BankingDetailsActionStatus';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';

export const InvestorBankingDetails = () => {
  const { translate } = useInternationalisation();

  const {
    bankingDetailsRequestError,
    makeBankingDetailsRequest,
    permissionsRequestError,
    makePermissionsRequest,
    deleteSuccessStatusCode,
  } = useInvestorBankingDetailsContext();

  if (permissionsRequestError) {
    return <RequestFailedAlert error={permissionsRequestError} retry={makePermissionsRequest} />;
  }

  if (bankingDetailsRequestError) {
    return (
      <RequestFailedAlert error={bankingDetailsRequestError} retry={makeBankingDetailsRequest} />
    );
  }

  return (
    <>
      {deleteSuccessStatusCode != null && (
        <Alert
          alertType="positive"
          header={translate('pages.bankingDetails.delete.successMessage.header')}
          withMarginTop={true}
        >
          {deleteSuccessStatusCode === bankingDetailsActionStatusesByCode.Authorised
            ? translate('pages.bankingDetails.delete.successMessage.authorised')
            : translate('pages.bankingDetails.delete.successMessage.pending')}
        </Alert>
      )}
      <DesktopOnly>
        <DesktopInvestorBankingDetails />
      </DesktopOnly>
      <MobileOnly>
        <MobileInvestorBankingDetails />
      </MobileOnly>
    </>
  );
};
