import React, { useCallback, useEffect, useState } from 'react';
import { usePostJson } from '../../infrastructure/api/usePostJson';
import { UserDetails } from './UserDetails';
import { useStartupData } from '../../startup/StartupDataContext';
import {
  clearSessionStorage,
  readSessionStorage,
  sessionStorageCodes,
  writeSessionStorage,
} from '../../infrastructure/hooks/useSessionStorage';

export type GetCurrentUserResponse = {
  user: UserDetails | null;
};

type AuthenticationContextValue = {
  isAuthenticated: () => boolean;
  setUser: (userDetails: UserDetails) => void;
  getUser: () => UserDetails;
  setSignedIn: () => void;
  logOut: (parameters: LogOutParameters) => void;
  endImpersonation: (parameters: EndImpersonationParameters) => void;
  refresh: () => void;
};

const throwNotInitialisedError = () => {
  throw new Error('AuthenticationContext has not yet been initialised');
};

export const AuthenticationContext = React.createContext<AuthenticationContextValue>({
  isAuthenticated: throwNotInitialisedError,
  setUser: throwNotInitialisedError,
  getUser: throwNotInitialisedError,
  setSignedIn: throwNotInitialisedError,
  logOut: throwNotInitialisedError,
  endImpersonation: throwNotInitialisedError,
  refresh: throwNotInitialisedError,
});

type Props = {
  children?: React.ReactNode;
};

export const AuthenticationContextProvider = ({ children }: Props) => {
  const { currentUser, setCurrentUser, silentlyRefreshSessionTimeoutSettings, refreshUserFromApi } =
    useStartupData();

  const [isLoggingOut, setIsLoggingOut] = useState(false);

  const signOutApiRequest = usePostJson<{}, {}>('/api/authentication/SignOut');

  const endImpersonationApiRequest = usePostJson<{}, {}>('/api/authentication/EndImpersonation');

  const isAuthenticated = useCallback(() => currentUser != null, [currentUser]);

  const setUser = useCallback(
    (newUser: UserDetails | null) => {
      setCurrentUser(newUser);
      silentlyRefreshSessionTimeoutSettings();
    },
    [setCurrentUser, silentlyRefreshSessionTimeoutSettings]
  );

  const setSignedIn = () => {
    writeSessionStorage(sessionStorageCodes.signedIn, 'true');
  };

  const getUser = useCallback((): UserDetails => {
    if (currentUser == null) {
      throw new Error('Cannot get user while unauthenticated');
    }
    return currentUser;
  }, [currentUser]);

  const logOut = useCallback(
    ({ onSuccess, onFailure }: LogOutParameters) => {
      signOutApiRequest.makeRequest({
        requestBody: {},
        onSuccess: () => {
          setUser(null);
          clearSessionStorage();

          if (onSuccess != null) {
            onSuccess();
          }
        },
        onFailure,
      });
    },
    [setUser, signOutApiRequest]
  );

  // Call the SignOut endpoint when sessionStorage.signedIn is null.
  // This means the user has closed the browser tab/window.
  useEffect(() => {
    const signedIn = readSessionStorage(sessionStorageCodes.signedIn);

    if (isAuthenticated() && signedIn == null && !isLoggingOut) {
      setIsLoggingOut(true);
      logOut({
        onFailure: () => setIsLoggingOut(false),
      });
    }
  }, [isAuthenticated, isLoggingOut, logOut]);

  const endImpersonation = ({ onSuccess, onFailure }: EndImpersonationParameters) => {
    if (currentUser == null || !currentUser.isImpersonated) {
      return;
    }

    endImpersonationApiRequest.makeRequest({
      requestBody: {},
      onSuccess,
      onFailure,
    });
  };

  const refresh = () => {
    refreshUserFromApi();
  };

  return (
    <AuthenticationContext.Provider
      value={{
        isAuthenticated,
        setUser,
        getUser,
        setSignedIn,
        logOut,
        endImpersonation,
        refresh,
      }}
    >
      {children}
    </AuthenticationContext.Provider>
  );
};

type LogOutParameters = {
  onSuccess?: () => void;
  onFailure?: (error: string) => void;
};

type EndImpersonationParameters = {
  onSuccess?: () => void;
  onFailure?: (error: string) => void;
};
