import { ApiResponse } from './ApiResponse';
import queryString from 'query-string';
import axios, { AxiosRequestHeaders, AxiosResponse, CancelTokenSource, ResponseType } from 'axios';
import { TranslateFunction } from '../../internationalisation/types/InternationalisationContextValue';
import { getErrorResponse } from './ErrorResponse';
import { getSuccessResponse } from './SuccessResponse';

export type HttpMethod = 'get' | 'post' | 'put' | 'delete';
export type QueryParameters = Record<string, any>;

const defaultRequestTimeoutInSeconds = 30;

export type MakeRequestParameters<TRequestBody> = {
  method: HttpMethod;
  endpointUrl: string;
  cancelTokenSource?: CancelTokenSource;
  requestBody?: TRequestBody;
  appendPath?: string;
  queryParameters?: QueryParameters;
  timeoutInSeconds?: number;
  responseType?: ResponseType;
};

export const makeRequest = async <TRequestBody, TResponse>(
  parameters: MakeRequestParameters<TRequestBody>,
  translate: TranslateFunction | null,
  siteId: number | null
): Promise<ApiResponse<TResponse>> => {
  const {
    method,
    endpointUrl,
    cancelTokenSource,
    requestBody,
    appendPath,
    queryParameters,
    timeoutInSeconds,
    responseType,
  } = parameters;

  let url = endpointUrl;

  if (appendPath != null) {
    url += '/' + appendPath;
  }

  if (queryParameters != null) {
    url += `?${queryString.stringify(queryParameters)}`;
  }

  const headers: AxiosRequestHeaders = {
    // Tells the back-end that this is an AJAX request and it should return a 401
    // when authorization fails, rather than redirecting to login.
    'X-Requested-With': 'XMLHttpRequest',
  };

  if (siteId != null) {
    headers[siteIdHeaderName] = siteId.toString();
  }

  const request = axios({
    method,
    url,
    cancelToken: cancelTokenSource?.token,
    data: requestBody,
    responseType: responseType ?? 'json',
    timeout: (timeoutInSeconds ?? defaultRequestTimeoutInSeconds) * 1000,
    headers,
  });

  return request
    .then((response: AxiosResponse<TResponse>) => getSuccessResponse(response))
    .catch((error: unknown) => getErrorResponse(error, translate));
};

// Keep this in sync with the `siteIdHeaderName` constant in `SiteContext.cs`
export const siteIdHeaderName = 'pfs-connect-site-id';
