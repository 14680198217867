import React, { useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { spacing32, spacing48 } from '../../design/spacing';
import { shadow2 } from '../../design/shadows';
import { Link, useMatch } from 'react-router-dom';
import { colourPrimary05, colourWhite } from '../../design/colours';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { IfUserHasRole } from '../../../features/authentication/UserRoles';
import { allNonAdminUserRoles } from '../../../features/authentication/UserRole';
import { mobileNavbarHeight, onDesktop, onMobile } from '../screenBreakpoints';
import { fadeIn } from '../../design/transitions';
import { DesktopOnly } from '../DesktopOnly';
import { UserMenu } from './UserMenu';
import { Notifications } from './notifications/Notifications';
import { menuItemStyles, sidebarWidth } from './SidebarStyles';
import { Logo } from '../../branding/Logo';
import { getIsoDatestampToday } from '../../../helpers/dateTimeHelpers';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { DynamicIcon } from '../../../icons/dynamicIcon';

export const Sidebar = ({ close }: SidebarProps) => {
  const { translate } = useInternationalisation();
  const [menuItems, setMenuItems] = useState<Array<MenuItem> | null>(null);

  const request = useGetJson<GetMenuItemsQuery, GetMenuItemsResponse>(
    'api/navigation/GetMenuItems'
  );
  useOnMount(() => {
    request.makeRequest({
      queryParameters: {
        localDate: getIsoDatestampToday(),
      },
      onSuccess: (response) => setMenuItems(response.menuItems),
    });
  });

  if (menuItems == null) return <></>;

  return (
    <SidebarNav>
      <SidebarTop>
        <DesktopOnly>
          <Link to="/">
            <Logo />
          </Link>
        </DesktopOnly>
        <NavLinks>
          {menuItems.map((item: MenuItem, index: number) => {
            return (
              <NavLink key={index} to={item.path} onClick={close}>
                <DynamicIcon name={item.icon} /> {translate(item.translationKey)}
              </NavLink>
            );
          })}
          <IfUserHasRole userRole={allNonAdminUserRoles}>
            <Notifications closeSidebar={close} />
          </IfUserHasRole>
        </NavLinks>
      </SidebarTop>
      <SidebarBottom>
        <UserMenu onRequestSidebarClose={close} />
      </SidebarBottom>
    </SidebarNav>
  );
};

const SidebarNav = styled.nav`
  min-height: 100vh;
  background-color: ${colourWhite};
  padding-bottom: ${spacing48};
  padding-top: var(--side-bar-top-padding, ${spacing48});
  width: ${sidebarWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 5;
  overflow-y: auto;

  ${onDesktop(
    css`
      box-shadow: ${shadow2};
    `
  )}

  ${onMobile(
    css`
      width: 100vw;
      padding: 0;
      animation: ${fadeIn} 0.35s ease;
      min-height: calc(100vh - ${mobileNavbarHeight});
    `
  )};
`;

const SidebarTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const NavLinks = styled.div`
  width: 100%;
  padding: ${spacing32} 0;
`;

const NavLink = (props: {
  to: string;
  children?: React.ReactNode;
  onClick?: () => void;
  alias?: string;
}) => {
  const routeMatch = useMatch(`${props.to}/*`);
  const aliasMatch = useMatch(`${props.alias || props.to}/*`);
  const highlight = routeMatch != null || aliasMatch ? 'highlight' : '';

  return (
    <StyledLink to={props.to} highlight={highlight} onClick={props.onClick}>
      {props.children}
    </StyledLink>
  );
};

/* Styled Components */
const StyledLink = styled(Link)<{ highlight: string }>`
  ${menuItemStyles};
  opacity: ${(props) => (props.highlight === 'highlight' ? 1 : 0.85)};
  border-left-color: ${(props) =>
    props.highlight === 'highlight' ? colourPrimary05 : 'transparent'};
`;

const SidebarBottom = styled.div`
  ${onMobile(css`
    padding-bottom: ${spacing32};
  `)}
`;

/* Types */
type SidebarProps = {
  close: () => void;
};

type MenuItem = {
  path: string;
  translationKey: string;
  icon: string;
};
export type GetMenuItemsResponse = {
  menuItems: Array<MenuItem>;
};
export type GetMenuItemsQuery = {
  localDate: string;
};
