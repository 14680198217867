import React, { useContext, useState } from 'react';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { GetDataForHoldingsQuery } from './types';
import { HoldingsContext } from './HoldingsContext';
import { getIsoDatestampToday } from '../../helpers/dateTimeHelpers';
import { AuthenticationContext } from '../authentication/AuthenticationContext';
import { Modal, ModalHeader } from '../../infrastructure/interface/components/Modal';
import { useFileDownload } from '../../infrastructure/api/useFileDownload';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { ErrorMessage } from '../../infrastructure/interface/components/ErrorMessage';
import { ExcelFileIcon } from '../../icons/icons';
import { MinimalButton } from '../../infrastructure/interface/buttons/MinimalButton';

export const ExportHoldingsToExcelButton = () => {
  const { translate } = useInternationalisation();
  const { getUser } = useContext(AuthenticationContext);

  const {
    pageNumber,
    resultsPerPage,
    endDate,
    companyId,
    equityAttributionId,
    investorId,
    agentId,
    dealerId,
    employeeId,
    latestResponse,
  } = useContext(HoldingsContext);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const openErrorModal = () => setShowErrorModal(true);
  const closeErrorModal = () => setShowErrorModal(false);

  const { downloadFile, inProgress, error } = useFileDownload<
    { query: GetDataForHoldingsQuery },
    undefined
  >({
    endpointUrl: '/api/holdings/ExportHoldingsToExcel',
    method: 'post',
  });

  const onClick = () => {
    const query: GetDataForHoldingsQuery = {
      pageNumber: pageNumber,
      resultsPerPage: resultsPerPage,
      eDate: endDate ?? getIsoDatestampToday(),
      companyId,
      equityAttributionId,
      investorId,
      agentId,
      dealerId,
      employeeId,
    };

    downloadFile({
      fileName: `${getUser().name}_Holdings_${format(new Date(), 'yy-MM-dd')}.xlsx`,
      requestBody: { query },
      queryParameters: undefined,
      onError: () => openErrorModal(),
    });
  };

  return (
    <>
      <MinimalButton
        icon={<ExcelFileIcon />}
        onClick={onClick}
        disabled={
          inProgress ||
          latestResponse == null ||
          (isEmpty(latestResponse.traditionalFunds) && isEmpty(latestResponse.privateEquityFunds))
        }
      >
        {inProgress
          ? translate('pages.holdings.exportButton.waitingText')
          : translate('pages.holdings.exportButton.text')}
      </MinimalButton>
      <Modal isOpen={showErrorModal} onRequestClose={closeErrorModal}>
        <ModalHeader
          title={translate('errors.apology')}
          withCloseButton={true}
          onRequestClose={closeErrorModal}
        />
        <ErrorMessage>{error}</ErrorMessage>
      </Modal>
    </>
  );
};
