import React, { useMemo, useState } from 'react';
import styled, { css } from 'styled-components/macro';
import { SelectOptions } from '../../infrastructure/interface/forms/BaseSelect';
import {
  getCustomSingleSelectStyles,
  SingleSelect,
} from '../../infrastructure/interface/forms/SingleSelect';
import { fontSizes, fontWeightBold } from '../../styling/design/fonts';
import { onDesktop, onMobile } from '../../styling/layout/screenBreakpoints';
import { AddressBookIcon } from '../../icons/icons';
import { CounterpartDetailsSearchModal } from '../search/CounterpartDetailsSearchModal';
import { colourGrey08 } from '../../styling/design/colours';
import { spacing24 } from '../../styling/design/spacing';
import { InputOrSelectOverlaidActionIcon } from '../../infrastructure/interface/forms/InputOrSelectOverlaidActionIcon';
import { IfUserHasRole } from '../authentication/UserRoles';
import { without } from 'lodash';
import { allUserRoles } from '../authentication/UserRole';
import { InvestorDetailsCounterpartDropdownOption } from './GetInvestorDetailsCounterpartDropdownOptions';

type Props = {
  options: Array<InvestorDetailsCounterpartDropdownOption>;
  onChange: (newCounterpartId: number | null) => void;
  value: number | null;
};

export const InvestorDetailsCounterpartDropdown = (props: Props) => {
  const options: SelectOptions<number | null> = useMemo(
    () => props.options.map((option) => ({ value: option.counterpartId, label: option.label })),
    [props.options]
  );

  const [searchModalIsOpen, setSearchModalIsOpen] = useState(false);

  const onModalSelectCounterpartId = (counterpartId: number) => {
    props.onChange(counterpartId);
    setSearchModalIsOpen(false);
  };

  return (
    <>
      <IfUserHasRole userRole="Advisor">
        <InputOrSelectOverlaidActionIcon
          icon={<AddressBookIcon />}
          onClickIcon={() => setSearchModalIsOpen(true)}
          iconColour={colourGrey08}
          iconSize={spacing24}
        >
          <StyledSelect<number | null>
            value={props.value}
            options={options}
            onChange={props.onChange}
            customStyles={selectComponentStyles}
            searchable={false}
            data-testid={investorDetailsCounterpartDropdownTestId}
            dropdownIcon={{ icon: <div /> }}
          />
        </InputOrSelectOverlaidActionIcon>
        {searchModalIsOpen && (
          <CounterpartDetailsSearchModal
            isOpen={searchModalIsOpen}
            onRequestClose={() => setSearchModalIsOpen(false)}
            onSelectCounterpartId={onModalSelectCounterpartId}
          />
        )}
      </IfUserHasRole>
      <IfUserHasRole userRole={without(allUserRoles, 'Advisor')}>
        <StyledSelect<number | null>
          value={props.value}
          options={options}
          onChange={props.onChange}
          customStyles={selectComponentStyles}
          searchable={false}
          data-testid={investorDetailsCounterpartDropdownTestId}
        />
      </IfUserHasRole>
    </>
  );
};

export const investorDetailsCounterpartDropdownTestId = 'investor-details-investor-dropdown';

const StyledSelect = styled(SingleSelect)`
  ${onMobile(css`
    font-size: ${fontSizes.large.mobile};
  `)}

  ${onDesktop(css`
    font-size: ${fontSizes.xxlarge.desktop};
  `)}
` as typeof SingleSelect;

const selectComponentStyles = getCustomSingleSelectStyles<number | null>({
  control: (state) => {
    const singleOption = state.options.length === 1;

    return {
      backgroundColor: 'none',
      border: 'none',
      width: 'fit-content',
      pointerEvents: singleOption ? 'none' : 'auto',
      paddingLeft: 0,
      fontWeight: fontWeightBold,
      '&:hover': {
        opacity: singleOption ? 1 : 0.75,
      },
      transition: 'opacity 0.25s ease',
      padding: 0,
    };
  },
  dropdownIndicator: (state) => (state.options.length === 1 ? { display: 'none' } : {}),
  valueContainer: () => ({
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingRight: '1.5rem',
  }),
  option: () => ({
    fontSize: fontSizes.medium.desktop,
  }),
});
