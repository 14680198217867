import { IsoDatestamp } from '../../helpers/dateTimeHelpers';

export type Transaction = {
  investorId: number | null;
  investorName: string | null;
  investorNumber: string | null;
  fund: string | null;
  shareSeries: string | null;
  date: IsoDatestamp | null;
  type: string | null;
  units: number | null;
  price: number | null;
  currency: string | null;
  value: number;
  dealNumber: number | null;
  status: TransactionStatus;
  chaserMessagesTranslationKeys: Array<string>;
  managerApproval: ManagerApproval;
  contractNoteDocumentId: number | null;
  contractNoteDocumentName: string | null;
  shareDecimals: number;
  priceDecimals: number;
  currencyDecimals: number;
  showInvestorDetailsLink: boolean;
};

export type TransactionStatus = 'Pending' | 'Confirmed';

export type GetDataForTransactionsQuery = {
  pageNumber: number;
  resultsPerPage: number;
  unapprovedOnly: boolean | null;
  investorId: number | null;
  startDate: IsoDatestamp | null;
  endDate: IsoDatestamp | null;
  transactionTypes: Array<string | null>;
  companyId: number | null;
  equityAttributionId: number | null;
  agentId: number | null;
  dealerId: number | null;
};

export type GetDataForTransactionsResponse = {
  transactions: Array<Transaction>;
  total: number;
};

export type TransactionsFilterState = {
  pageNumber: number;
  resultsPerPage: number;
  investorId: number | null;
  companyId: number | null;
  equityAttributionId: number | null;
  startDate: IsoDatestamp | null;
  endDate: IsoDatestamp | null;
  transactionTypes: Array<string | null>;
  agentId: number | null;
  dealerId: number | null;
  unapprovedOnly: boolean | null;
};

export enum ManagerApproval {
  Hide = 'hide',
  NotApplicable = 'N/A',
  Approved = 'Y',
  Unapproved = 'N',
}
