import React, { useContext, useEffect, useState } from 'react';
import { GetInvestorBankingDetailsResponse } from './GetInvestorBankingDetailsResponse';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';
import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { isEqual } from 'lodash';
import { GetInvestorBankingDetailsPermissionsResponse } from './GetInvestorBankingDetailsPermissionsResponse';
import { BankingDetailsActionResponse } from './BankingDetailsActionResponse';
import { BankingDetailsActionStatusCode } from './BankingDetailsActionStatus';

type InvestorBankingDetailsContextValue = {
  bankingDetailsRequestInProgress: boolean;
  bankingDetailsRequestError: string | null;
  latestBankingDetailsResponse: GetInvestorBankingDetailsResponse | null;
  makeBankingDetailsRequest: () => void;
  permissionsRequestInProgress: boolean;
  permissionsRequestError: string | null;
  permissionsResponse: GetInvestorBankingDetailsPermissionsResponse | null;
  makePermissionsRequest: () => void;
  onDeleteSuccess: (response: BankingDetailsActionResponse) => void;
  deleteSuccessStatusCode: BankingDetailsActionStatusCode | null;
};

const InvestorBankingDetailsContext = React.createContext<
  InvestorBankingDetailsContextValue | undefined
>(undefined);

export const useInvestorBankingDetailsContext = () => {
  const contextValue = useContext(InvestorBankingDetailsContext);

  if (!contextValue) {
    throw new Error(
      'useInvestorBankingDetailsContext must be used within a InvestorBankingDetailsContextProvider'
    );
  }

  return contextValue;
};

type Props = {
  children: React.ReactNode;
};

export const InvestorBankingDetailsContextProvider = ({ children }: Props) => {
  const investorId = useInvestorIdUrlParam();

  const [latestBankingDetailsResponse, setLatestBankingDetailsResponse] =
    useState<GetInvestorBankingDetailsResponse | null>(null);

  const getInvestorBankingDetailsRequest = useGetJson<
    GetInvestorBankingDetailsQuery,
    GetInvestorBankingDetailsResponse
  >('/api/banking-details/GetInvestorBankingDetails');
  const { inProgress: bankingDetailsRequestInProgress, error: bankingDetailsRequestError } =
    getInvestorBankingDetailsRequest.state;

  const getInvestorBankingDetailsPermissionsRequest = useGetJson<
    GetInvestorBankingDetailsPermissionsQuery,
    GetInvestorBankingDetailsPermissionsResponse
  >('/api/banking-details/GetInvestorBankingDetailsPermissions');
  const {
    response: permissionsResponse,
    inProgress: permissionsRequestInProgress,
    error: permissionsRequestError,
  } = getInvestorBankingDetailsPermissionsRequest.state;

  const makeBankingDetailsRequest = () =>
    getInvestorBankingDetailsRequest.makeRequest({
      queryParameters: { investorId },
      onSuccess: (response) => {
        if (!isEqual(response, latestBankingDetailsResponse)) {
          setLatestBankingDetailsResponse(response);
        }
      },
    });

  const makePermissionsRequest = () =>
    getInvestorBankingDetailsPermissionsRequest.makeRequest({
      queryParameters: { investorId },
    });

  useEffect(() => {
    makeBankingDetailsRequest();
    makePermissionsRequest();
    setDeleteSuccessStatusCode(null);
  }, [investorId]); // eslint-disable-line react-hooks/exhaustive-deps

  const [deleteSuccessStatusCode, setDeleteSuccessStatusCode] =
    useState<BankingDetailsActionStatusCode | null>(null);

  const onDeleteSuccess = (actionResponse: BankingDetailsActionResponse) => {
    setDeleteSuccessStatusCode(actionResponse.statusCode);
    makeBankingDetailsRequest();
  };

  return (
    <InvestorBankingDetailsContext.Provider
      value={{
        bankingDetailsRequestInProgress,
        bankingDetailsRequestError,
        latestBankingDetailsResponse,
        makeBankingDetailsRequest,
        permissionsRequestInProgress,
        permissionsRequestError,
        permissionsResponse,
        makePermissionsRequest,
        onDeleteSuccess,
        deleteSuccessStatusCode,
      }}
    >
      {children}
    </InvestorBankingDetailsContext.Provider>
  );
};

type GetInvestorBankingDetailsQuery = {
  investorId: number | null;
};

type GetInvestorBankingDetailsPermissionsQuery = {
  investorId: number | null;
};
