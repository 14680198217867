import React from 'react';
import { baseChartOptions, BaseChartProps, Chart } from './Chart';
import { AxisTypeValue, PointOptionsObject, XAxisOptions, YAxisOptions } from 'highcharts';
import { useInternationalisation } from '../../internationalisation/hooks/useInternationalisation';
import { toIsoDatestampFromDate } from '../../helpers/dateTimeHelpers';

export type LineGraphDataPoint = {
  x: number | Date;
  y: number;
};

type Props = BaseChartProps & {
  xAxisType?: AxisTypeValue;
  dataPoints: Array<LineGraphDataPoint>;
  xAxisOptions?: XAxisOptions;
  yAxisOptions?: YAxisOptions;
};

export const LineGraph = ({
  xAxisType = 'linear',
  dataPoints,
  xAxisOptions,
  yAxisOptions,
  tooltipOptions,
  ...baseChartProps
}: Props) => {
  const { formatNumber, formatDate } = useInternationalisation();

  const chartOptions: Highcharts.Options = {
    ...baseChartOptions(baseChartProps),
    series: [
      {
        type: 'line',
        data: dataPoints.map(mapLineGraphDataPointToPointOptions),
        tooltip: tooltipOptions || {
          headerFormat: '',
          pointFormatter() {
            const formattedX =
              xAxisType === 'datetime'
                ? formatDate(toIsoDatestampFromDate(new Date(this.x)))
                : this.x;
            const formattedY = this.y === undefined ? '' : formatNumber(this.y);
            return `<b>${formattedX}</b><br/>${formattedY}`;
          },
        },
      },
    ],
    xAxis: {
      type: xAxisType,
      ...xAxisOptions,
    },
    yAxis: {
      title: {
        text: undefined,
      },
      ...yAxisOptions,
    },
    legend: {
      enabled: false,
    },
  };

  return (
    <Chart data-testid={baseChartProps['data-testid'] ?? 'line-graph'} options={chartOptions} />
  );
};

const mapLineGraphDataPointToPointOptions = (point: LineGraphDataPoint): PointOptionsObject => {
  if (typeof point.x == 'number') {
    return point as PointOptionsObject;
  } else {
    return {
      x: point.x.getTime(),
      y: point.y,
    };
  }
};
