import React from 'react';
import styled, { css } from 'styled-components/macro';
import { inRange, range } from 'lodash';
import { ChevronLeftIcon, ChevronRightIcon, EllipsisIcon } from '../../../icons/icons';
import { colourBlack, colourGrey01 } from '../../../styling/design/colours';
import { GhostButton } from '../buttons/GhostButton';
import { spacing12 } from '../../../styling/design/spacing';
import { IconButton } from '../buttons/IconButton';

export type PaginationControlProps = {
  totalPages: number;
  currentPage: number;
  visiblePageNumberBuffer?: number;
  setCurrentPage: (currentPage: number) => void;
  disabled?: boolean;
  className?: string;
};

// < 1 ... 4 5 [6] 7 8 ... 11 >
const defaultVisiblePageNumberBuffer = 2;

export const paginationControlTestId = 'pagination-control';
export const paginationCurrentPageIndicatorTestId = 'pagination-control-current-page';
export const paginationEllipsisTestId = 'pagination-control-ellipsis';

export const paginationBackNavTestId = 'pagination-control-back';
export const paginationForwardNavTestId = 'pagination-control-forward';
export const paginationPageNavTestId = (pageNumber: number) =>
  `pagination-control-page-nav-${pageNumber}`;

export const PaginationControl = (props: PaginationControlProps) => {
  const { totalPages, currentPage, setCurrentPage, disabled, className } = props;

  if (totalPages < 0) {
    throw new Error("Total pages can't be negative.");
  }

  const firstPage = 1;
  const lastPage: number = Math.max(1, currentPage, totalPages);
  const visiblePageNumberBuffer: number =
    props.visiblePageNumberBuffer != null
      ? props.visiblePageNumberBuffer
      : defaultVisiblePageNumberBuffer;

  const visiblePageNumbers: Array<number> = getVisiblePageNumbers(
    firstPage,
    lastPage,
    currentPage,
    visiblePageNumberBuffer
  );

  return (
    <PaginationControlWrapper className={className} data-testid={paginationControlTestId}>
      <BackForwardPageNavButton
        data-testid={paginationBackNavTestId}
        icon={<ChevronLeftIcon />}
        buttonStyle="ghost"
        onClick={() => setCurrentPage(currentPage - 1)}
        disabled={disabled || currentPage === firstPage}
      />
      {visiblePageNumbers.map((pageNumber, index, pageNumbers) => {
        const pageNavButton =
          pageNumber === currentPage ? (
            <CurrentPageIndicator
              data-testid={paginationCurrentPageIndicatorTestId}
              key={pageNumber}
              disabled={disabled}
            >
              {pageNumber}
            </CurrentPageIndicator>
          ) : (
            <PageNavButton
              data-testid={paginationPageNavTestId(pageNumber)}
              key={pageNumber}
              onClick={() => setCurrentPage(pageNumber)}
              disabled={disabled}
            >
              <PageNavNumberContainer>{pageNumber}</PageNavNumberContainer>
            </PageNavButton>
          );

        const nextPageNumber: number = pageNumbers[index + 1];
        return !nextPageNumber || nextPageNumber === pageNumber + 1 ? (
          pageNavButton
        ) : (
          <React.Fragment key={pageNumber}>
            {pageNavButton}
            <PaginationDotsContainer data-testid={paginationEllipsisTestId} disabled={disabled}>
              <EllipsisIcon />
            </PaginationDotsContainer>
          </React.Fragment>
        );
      })}
      <BackForwardPageNavButton
        data-testid={paginationForwardNavTestId}
        icon={<ChevronRightIcon />}
        buttonStyle="ghost"
        onClick={() => setCurrentPage(currentPage + 1)}
        disabled={disabled || currentPage === lastPage}
      />
    </PaginationControlWrapper>
  );
};

const paginationControlHeight = '28px';
const paginationControlButtonMargin = '2px';

const PaginationControlWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const pageNumberStyles = css`
  border: 1px solid transparent;
  border-radius: 5px;

  height: ${paginationControlHeight};
  width: ${paginationControlHeight};
  padding: 0;
  margin: 0 ${paginationControlButtonMargin};
`;

const PageNavButton = styled(GhostButton)`
  ${pageNumberStyles};
`;

const PageNavNumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const CurrentPageIndicator = styled.div<{ disabled?: boolean }>`
  ${pageNumberStyles};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: ${colourGrey01};
  opacity: ${(props) => (props.disabled ? 0.5 : null)};
`;

const PaginationDotsContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  color: ${colourBlack};

  height: ${paginationControlHeight};
  width: ${paginationControlHeight};
  padding: 0;
  margin: 0 ${paginationControlButtonMargin};
  opacity: ${(props) => (props.disabled ? 0.5 : null)};

  svg {
    height: ${spacing12};
    width: ${spacing12};
  }
`;

const BackForwardPageNavButton = styled(IconButton)`
  height: ${paginationControlHeight};
  width: ${paginationControlHeight};
  padding: ${spacing12};
  margin: 0;
  color: ${colourBlack};
  border-radius: 5px;

  svg {
    height: ${spacing12};
    width: ${spacing12};
  }

  &:first-of-type {
    margin-right: ${paginationControlButtonMargin};
  }

  &:last-of-type {
    margin-left: ${paginationControlButtonMargin};
  }
`;

export const getVisiblePageNumbers = (
  firstPage: number,
  lastPage: number,
  currentPage: number,
  visiblePageNumberBuffer: number
): Array<number> => {
  if (firstPage < 1 || lastPage < 1 || currentPage < 1) {
    throw new Error('First, last, and current page parameters cannot be less than one.');
  }

  if (visiblePageNumberBuffer < 0) {
    throw new Error('VisiblePageNumberBuffer cannot be less than zero.');
  }

  if (lastPage < firstPage) {
    throw new Error('Last page cannot be less than first page.');
  }

  if (!inRange(currentPage, firstPage, lastPage + 1)) {
    throw new Error('Current page must be between first and last page (inclusive).');
  }

  const totalPages = lastPage - firstPage + 1;
  // 2 end pages, 2 ellipses, total visible page number buffer, and current page.
  const totalItemsToShow = 2 + 2 + visiblePageNumberBuffer * 2 + 1;
  if (totalPages <= totalItemsToShow) {
    return range(firstPage, lastPage + 1);
  }

  const shouldShowStartEllipsis = currentPage - visiblePageNumberBuffer > firstPage + 2;
  const shouldShowEndEllipsis = currentPage + visiblePageNumberBuffer < lastPage - 2;

  if (shouldShowStartEllipsis && shouldShowEndEllipsis) {
    return [
      firstPage,
      ...range(currentPage - visiblePageNumberBuffer, currentPage + visiblePageNumberBuffer + 1),
      lastPage,
    ];
  } else if (shouldShowStartEllipsis) {
    // Minus 2 to account for missing end ellipsis and current page.
    return [firstPage, ...range(lastPage - visiblePageNumberBuffer * 2 - 2, lastPage + 1)];
  } else if (shouldShowEndEllipsis) {
    // Add 3 to account for missing start ellipsis, current page, and exclusive upper bound
    return [...range(firstPage, firstPage + visiblePageNumberBuffer * 2 + 3), lastPage];
  }

  throw new Error('Calculation of page numbers does not match expected cases.');
};
