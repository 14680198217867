import { createContext } from 'react';
import { getIsoDatestampToday, IsoDatestamp } from '../../helpers/dateTimeHelpers';
import { GetDataForHoldingsResponse, HoldingsFilterState, FundExpandedState } from './types';
import { defaultInitialResultsPerPageOption } from '../../infrastructure/interface/tables/PaginatedTable';

const throwNotInitialisedError = () => {
  throw new Error('Transactions Page Context is not yet initialised');
};

export const HoldingsContext = createContext<HoldingsContextValue>({
  pageNumber: 1,
  setPageNumber: throwNotInitialisedError,
  resultsPerPage: defaultInitialResultsPerPageOption,
  setResultsPerPage: throwNotInitialisedError,
  companyId: null,
  setCompanyId: throwNotInitialisedError,
  companySelectLoaded: false,
  setCompanySelectLoaded: throwNotInitialisedError,
  equityAttributionId: null,
  setEquityAttributionId: throwNotInitialisedError,
  equityAttributionSelectLoaded: false,
  setEquityAttributionSelectLoaded: throwNotInitialisedError,
  investorId: null,
  setInvestorId: throwNotInitialisedError,
  investorSelectLoaded: false,
  setInvestorSelectLoaded: throwNotInitialisedError,
  agentId: null,
  setAgentId: throwNotInitialisedError,
  agentSelectLoaded: false,
  setAgentSelectLoaded: throwNotInitialisedError,
  dealerId: null,
  setDealerId: throwNotInitialisedError,
  dealerSelectLoaded: false,
  setDealerSelectLoaded: throwNotInitialisedError,
  employeeId: null,
  setEmployeeId: throwNotInitialisedError,
  employeeSelectLoaded: false,
  setEmployeeSelectLoaded: throwNotInitialisedError,
  endDate: null,
  setEndDate: throwNotInitialisedError,
  filterLoadError: null,
  setFilterLoadError: throwNotInitialisedError,
  fundExpandedStates: [],
  setFundExpandedStates: throwNotInitialisedError,
  latestResponse: null,
  inProgress: false,
  resetFilters: throwNotInitialisedError,
  showEqualisationAdjustmentMessage: false,
  setShowEqualisationAdjustmentMessage: throwNotInitialisedError,
});

type HoldingsContextValue = {
  pageNumber: number;
  setPageNumber(pageNumber: number): void;
  resultsPerPage: number;
  setResultsPerPage(resultsPerPage: number): void;
  companyId: number | null;
  setCompanyId(companyId: number | null): void;
  companySelectLoaded: boolean | false;
  setCompanySelectLoaded(companySelectLoaded: boolean): void;
  equityAttributionId: number | null;
  setEquityAttributionId(equityAttributionId: number | null): void;
  equityAttributionSelectLoaded: boolean | false;
  setEquityAttributionSelectLoaded(equityAttributionSelectLoaded: boolean): void;
  investorId: number | null;
  setInvestorId(investorId: number | null): void;
  investorSelectLoaded: boolean | false;
  setInvestorSelectLoaded(investorSelectLoaded: boolean): void;
  agentId: number | null;
  setAgentId(agentId: number | null): void;
  agentSelectLoaded: boolean | false;
  setAgentSelectLoaded(agentSelectLoaded: boolean): void;
  dealerId: number | null;
  setDealerId(dealerId: number | null): void;
  dealerSelectLoaded: boolean | false;
  setDealerSelectLoaded(dealerSelectLoaded: boolean): void;
  employeeId: number | null;
  setEmployeeId(employeeId: number | null): void;
  employeeSelectLoaded: boolean | false;
  setEmployeeSelectLoaded(employeeSelectLoaded: boolean): void;
  endDate: IsoDatestamp | null;
  setEndDate(date: IsoDatestamp | null): void;
  latestResponse: GetDataForHoldingsResponse | null;
  inProgress: boolean;
  filterLoadError: string | null;
  setFilterLoadError(filterLoadError: string | null): void;
  fundExpandedStates: Array<FundExpandedState>;
  setFundExpandedStates(state: Array<FundExpandedState>): void;
  resetFilters(): void;
  showEqualisationAdjustmentMessage: boolean;
  setShowEqualisationAdjustmentMessage(showEqualisationAdjustmentMessage: boolean): void;
};

export const defaultHoldingsFilterState: HoldingsFilterState = {
  pageNumber: 1,
  resultsPerPage: defaultInitialResultsPerPageOption,
  companyId: null,
  equityAttributionId: null,
  investorId: null,
  agentId: null,
  dealerId: null,
  employeeId: null,
  endDate: getIsoDatestampToday(),
};
