import React, { useState } from 'react';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  GetEmployeeDropdownOptionsResponse,
  mapEmployeeDropdownOptionsResponseToSelectOptions,
} from './GetEmployeeDropdownOptionsResponse';
import { MetadataSingleSelectComponentProps } from '../MetadataSelectProps';
import { MetadataSingleSelect } from '../MetadataSingleSelect';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';

export type EmployeeSelectOptionValue = number | null;

type Props = Omit<
  MetadataSingleSelectComponentProps<
    EmployeeSelectOptionValue,
    {},
    GetEmployeeDropdownOptionsResponse
  >,
  'settings'
>;

export const EmployeeSelect = (props: Props) => {
  const { translate } = useInternationalisation();
  const [shouldShow, setShouldShow] = useState(true);

  const handleOnLoaded = (response: GetEmployeeDropdownOptionsResponse) => {
    if (response.options.length <= 1) setShouldShow(false);
    if (props.onLoaded) props.onLoaded(response);
  };

  const placeholder = translate('metadata.employeeSelect.placeholder');
  const loadingPlaceholder = translate('metadata.employeeSelect.loadingPlaceholder');

  const renderContent = () => {
    return (
      <>
        <MetadataSingleSelect<
          EmployeeSelectOptionValue,
          {},
          GetEmployeeDropdownOptionsRequest,
          GetEmployeeDropdownOptionsResponse
        >
          {...props}
          endpointUrl="/api/metadata/GetEmployeeDropdownOptions"
          settings={{}}
          mapSettingsToQueryParameters={(settings) => settings}
          mapResponseToOptions={mapEmployeeDropdownOptionsResponseToSelectOptions}
          placeholder={placeholder}
          loadingPlaceholder={loadingPlaceholder}
          minWidthInPixels={350}
          onLoaded={handleOnLoaded}
        />
      </>
    );
  };

  if (!shouldShow) return null;
  return <>{props.wrapInDiv ? <div>{renderContent()}</div> : renderContent()}</>;
};

type GetEmployeeDropdownOptionsRequest = {};
