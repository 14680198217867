import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router';
import styled from 'styled-components/macro';
import { EditButton } from '../../../infrastructure/interface/buttons/EditButton';
import { Panel } from '../../../infrastructure/interface/components/Panel';
import {
  NoResultsRow,
  Table,
  TableLoadingOverlay,
  TBody,
  Td,
  Th,
  THead,
  Tr,
} from '../../../infrastructure/interface/tables/Table';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing16 } from '../../../styling/design/spacing';
import { DeleteDistributionReinvestmentButton } from './DeleteDistributionReinvestmentButton';
import { useDistributionReinvestmentsContext } from './DistributionReinvestmentsContext';

export const DesktopDistributionReinvestmentsList = () => {
  const { translate, formatDate } = useInternationalisation();
  const navigate = useNavigate();

  const {
    latestDistributionReinvestmentsResponse,
    distributionReinvestmentsRequestInProgress,
    permissionsResponse,
  } = useDistributionReinvestmentsContext();

  if (latestDistributionReinvestmentsResponse == null) {
    return null;
  }

  return (
    <Panel>
      <TableLoadingOverlay showOverlay={distributionReinvestmentsRequestInProgress}>
        <Table>
          <THead>
            <Tr>
              <Th>{translate('pages.distributionReinvestments.fieldNames.fundName')}</Th>
              <Th>{translate('pages.distributionReinvestments.fieldNames.validFrom')}</Th>
              <Th>
                {translate('pages.distributionReinvestments.fieldNames.reinvestmentPreference')}
              </Th>
              <Th />
            </Tr>
          </THead>
          <TBody>
            {isEmpty(latestDistributionReinvestmentsResponse.distributionReinvestments) ? (
              <NoResultsRow
                colSpan={4}
                message={translate('pages.distributionReinvestments.noDistributionReinvestments')}
              />
            ) : (
              latestDistributionReinvestmentsResponse.distributionReinvestments.map(
                (distributionReinvestment, index) => (
                  <Tr key={distributionReinvestment.distributionReinvestmentId}>
                    <Td>{distributionReinvestment.fundName}</Td>
                    <Td>{formatDate(distributionReinvestment.validFrom)}</Td>
                    <Td>{distributionReinvestment.reinvestmentPreference}</Td>
                    <Td align="right">
                      <ActionButtonsContainer>
                        {distributionReinvestment.editable && (
                          <EditButton
                            onClick={() =>
                              navigate(
                                `${distributionReinvestment.distributionReinvestmentId}/edit`
                              )
                            }
                          />
                        )}
                        {permissionsResponse?.canEditDistributionReinvestments === true && (
                          <DeleteDistributionReinvestmentButton
                            distributionReinvestmentId={
                              distributionReinvestment.distributionReinvestmentId
                            }
                            size="medium"
                          />
                        )}
                      </ActionButtonsContainer>
                    </Td>
                  </Tr>
                )
              )
            )}
          </TBody>
        </Table>
      </TableLoadingOverlay>
    </Panel>
  );
};

const ActionButtonsContainer = styled.div`
  display: inline-flex;
  gap: ${spacing16};
`;
