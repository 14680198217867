import React, { createContext, useContext, useState } from 'react';
import { UserDetails } from '../features/authentication/UserDetails';
import { useGetJsonWithoutTranslation } from '../infrastructure/api/useGetJson';
import { GetCurrentUserResponse } from '../features/authentication/AuthenticationContext';
import { ErrorSplashScreen, LoadingSplashScreen } from '../features/SplashScreen';
import { GetAvailableLanguagesResponse } from '../internationalisation/hooks/useInternationalisationContextValue';
import { useOnMount } from '../infrastructure/hooks/useOnMount';
import { GetSessionTimeoutSettingsResponse } from '../features/authentication/GetSessionTimeoutSettingsResponse';
import { ApiRequestState } from '../infrastructure/api/ApiRequestState';
import { find } from 'lodash';

export type StartupDataContextValue = {
  currentUser: UserDetails | null;
  setCurrentUser: (value: UserDetails | null) => void;
  refreshUserFromApi: () => void;
  availableLanguageNames: Array<string>;
  defaultLanguageName: string;
  sessionTimeoutSettings: GetSessionTimeoutSettingsResponse;
  silentlyRefreshSessionTimeoutSettings: () => void;
};

export const StartupDataContext = createContext<StartupDataContextValue | undefined>(undefined);

export const useStartupData = () => {
  const startupData = useContext(StartupDataContext);

  if (startupData == null) {
    throw new Error('useStartupData must be called within a <StartupDataContextProvider />');
  }

  return startupData;
};

export const StartupDataContextProvider = (props: { children: React.ReactNode }) => {
  // This should only be set from within StartupDataContextProvider, or AuthenticationContextProvider.
  // All other components should use AuthenticationContext.getUser to obtain the current user.
  // User state cannot be stored in AuthenticationContext, as it re-renders on language change
  // as it lies (and must lie) within InternationalisationContextProvider.
  const [currentUser, setCurrentUser] = useState<UserDetails | null>(null);

  const getCurrentUserApiRequest = useGetJsonWithoutTranslation<undefined, GetCurrentUserResponse>(
    '/api/authentication/GetCurrentUser'
  );
  const getCurrentUserFromApi = () =>
    getCurrentUserApiRequest.makeRequest({
      onSuccess: (response) => setCurrentUser(response.user),
    });

  const getAvailableLanguagesRequest = useGetJsonWithoutTranslation<
    undefined,
    GetAvailableLanguagesResponse
  >('/api/internationalisation/GetAvailableLanguages');

  // We want the ability to silently refresh the session timeout settings
  const [cachedSessionTimeoutSettings, setCachedSessionTimeoutSettings] =
    useState<GetSessionTimeoutSettingsResponse | null>(null);

  const loadSessionTimeoutSettings = () => {
    getSessionTimeoutSettingsRequest.makeRequest({
      onSuccess: setCachedSessionTimeoutSettings,
    });
  };

  const getSessionTimeoutSettingsRequest = useGetJsonWithoutTranslation<
    undefined,
    GetSessionTimeoutSettingsResponse
  >('/api/authentication/GetSessionTimeoutSettings');

  useOnMount(() => {
    getCurrentUserFromApi();
    getAvailableLanguagesRequest.makeRequest();
    loadSessionTimeoutSettings();
  });

  const error = getErrorIfAny([
    getCurrentUserApiRequest,
    getAvailableLanguagesRequest,
    getSessionTimeoutSettingsRequest,
  ]);

  if (error != null) {
    return <ErrorSplashScreen error={error} translate={null} />;
  }

  const inProgress =
    getCurrentUserApiRequest.state.inProgress ||
    getAvailableLanguagesRequest.state.inProgress ||
    (getSessionTimeoutSettingsRequest.state.inProgress && cachedSessionTimeoutSettings == null);

  const currentUserResponse = getCurrentUserApiRequest.state.response;
  const availableLanguagesResponse = getAvailableLanguagesRequest.state.response;

  if (
    inProgress ||
    currentUserResponse == null ||
    availableLanguagesResponse == null ||
    cachedSessionTimeoutSettings == null
  ) {
    return <LoadingSplashScreen translate={null} />;
  }

  const availableLanguageNames = availableLanguagesResponse.languageNames;
  const defaultLanguageName = availableLanguagesResponse.defaultLanguage;

  const refreshUserFromApi = () => {
    getCurrentUserFromApi();
    loadSessionTimeoutSettings(); // Timeout settings change depending on the user
  };

  return (
    <StartupDataContext.Provider
      value={{
        currentUser,
        setCurrentUser,
        refreshUserFromApi,
        availableLanguageNames,
        defaultLanguageName,
        sessionTimeoutSettings: cachedSessionTimeoutSettings,
        silentlyRefreshSessionTimeoutSettings: loadSessionTimeoutSettings,
      }}
    >
      {props.children}
    </StartupDataContext.Provider>
  );
};

const getErrorIfAny = (requests: Array<{ state: ApiRequestState<unknown> }>): string | null =>
  find(requests, (request) => request.state.error != null)?.state.error ?? null;
