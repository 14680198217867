import { FormikHelpers } from 'formik';
import { useState } from 'react';
import styled from 'styled-components/macro';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { usePostJson } from '../../../infrastructure/api/usePostJson';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { BackButton } from '../../../infrastructure/interface/components/BackButton';
import { Header1 } from '../../../infrastructure/interface/components/Headers';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { spacing24 } from '../../../styling/design/spacing';
import { CentredPaddedPage, PaddedPage } from '../../../styling/layout/PaddedPage';
import { RequiresUserRole } from '../../authentication/UserRoles';
import { GetInvestorDetailsResponse } from '../GetInvestorDetailsResponse';
import { useGetInvestorDetailsRequest } from '../useGetInvestorDetailsRequest';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';
import {
  EditInvestorDetailsForm,
  editInvestorDetailsFormFieldNames,
  EditInvestorDetailsFormModel,
  EditInvestorDetailsFormStatus,
} from './EditInvestorDetailsForm';
import { init } from 'i18next';

export const EditInvestorDetails = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.editInvestorDetails.title'));

  return (
    <RequiresUserRole userRole={['Investor', 'Consolidated Investor', 'Advisor']}>
      <EditInvestorDetailsApiRequestStateWrapper />
    </RequiresUserRole>
  );
};

const EditInvestorDetailsApiRequestStateWrapper = () => {
  const { translate } = useInternationalisation();
  const investorId = useInvestorIdUrlParam();
  const getInvestorDetailsRequest = useGetInvestorDetailsRequest();

  const makeRequest = () => {
    getInvestorDetailsRequest.makeRequest({
      queryParameters: { investorId },
    });
  };

  useOnMount(() => {
    makeRequest();
  });

  return (
    <ApiRequestStateWrapper apiRequestState={getInvestorDetailsRequest.state} retry={makeRequest}>
      {(response) =>
        response.editable ? (
          <EditInvestorDetailsComponent initialInvestorDetails={response} />
        ) : (
          <PaddedPage>
            <Alert alertType="negative" header={translate('errors.generic')} withMarginTop={true}>
              {translate('pages.editInvestorDetails.permissionDenied')}
            </Alert>
          </PaddedPage>
        )
      }
    </ApiRequestStateWrapper>
  );
};

type EditInvestorDetailsComponentProps = {
  initialInvestorDetails: GetInvestorDetailsResponse;
};

const EditInvestorDetailsComponent = (props: EditInvestorDetailsComponentProps) => {
  const { initialInvestorDetails } = props;
  const { translate } = useInternationalisation();
  const investorIdFromUrl = useInvestorIdUrlParam();

  const [formStatus, setFormStatus] = useState<EditInvestorDetailsFormStatus>('NotSubmitted');

  const editInvestorDetailsRequest = usePostJson<
    EditInvestorDetailsCommand,
    EditInvestorDetailsResponse
  >('/api/investors/EditInvestorDetails');

  const headerText = (): string => {
    const invDetails = initialInvestorDetails;
    if (investorIdFromUrl == null) return translate('pages.editInvestorDetails.myDetails');
    let header = invDetails.name;
    if (invDetails.alternativeName) header += ` ${invDetails.alternativeName}`;
    if (invDetails.investorNumber && invDetails.investorNumber.length > 0) {
      header += ` ${invDetails.investorNumber}`;
    }
    return header;
  };

  const onFormSubmit = async (
    formModel: EditInvestorDetailsFormModel,
    formikHelpers: FormikHelpers<EditInvestorDetailsFormModel>
  ) => {
    const skipSyncCheck = formStatus === 'IgnoreSyncConfirmed';
    setFormStatus('Submitting');

    editInvestorDetailsRequest.makeRequest({
      requestBody: {
        ...formModel,
        counterpartId: investorIdFromUrl,
        skipSyncCheck,
      },
      onSuccess: (response) => setFormStatus(response.status),
      onFailure: () => setFormStatus('NotSubmitted'),
    });
  };

  return (
    <CentredPaddedPage>
      <Header1>{headerText()}</Header1>
      <StyledBackButton />
      <EditInvestorDetailsForm
        initialValues={mapGetInvestorDetailsResponseToInitialFormValues(initialInvestorDetails)}
        onSubmit={onFormSubmit}
        investorNumber={initialInvestorDetails.investorNumber}
        status={formStatus}
        setStatus={setFormStatus}
        error={editInvestorDetailsRequest.state.error}
        blockedFields={initialInvestorDetails.blockedFields}
      />
    </CentredPaddedPage>
  );
};

const mapGetInvestorDetailsResponseToInitialFormValues = (
  response: GetInvestorDetailsResponse
): EditInvestorDetailsFormModel =>
  editInvestorDetailsFormFieldNames.reduce(
    (accumulator, fieldName) => ({ ...accumulator, [fieldName]: response[fieldName] ?? '' }),
    {} as EditInvestorDetailsFormModel
  );

const StyledBackButton = styled(BackButton)`
  margin-bottom: ${spacing24};
`;

export type EditInvestorDetailsCommand = EditInvestorDetailsFormModel & {
  counterpartId: number | null;
  skipSyncCheck: boolean;
};

export type EditInvestorDetailsResponse = {
  status: EditInvestorDetailsFormStatus;
};
