//investment allocation graph

import { useGetJson } from '../../../infrastructure/api/useGetJson';
import { useDashboardFilters } from '../DashboardFiltersContext';
import React, { useEffect } from 'react';
import { DashboardComponentApiRequestStateWrapper } from '../DashboardComponentApiRequestStateWrapper';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { DashboardComponentLayout } from '../DashboardComponentLayout';
import { PieChart } from '../../../infrastructure/charts/PieChart';
import { colourPrimary05 } from '../../../styling/design/colours';
import { MessageContainer } from './MessageContainer';
import { ComponentResponse } from '../DashboardComponent';

export const investmentAllocationGraphComponentName = 'Investment Allocation Graph';

export const InvestmentAllocationGraph = () => {
  const endpointUrl = 'api/dashboards/GetDataForInvestmentAllocationGraph';
  const getRequest = useGetJson<
    GetDataForInvestmentAllocationGraphQuery,
    GetDataForInvestmentAllocationGraphResponse
  >(endpointUrl);

  const { fromDate } = useDashboardFilters();

  const makeRequest = () => {
    if (fromDate != null) {
      getRequest.makeRequest({
        queryParameters: {
          runDate: fromDate,
        },
      });
    }
  };

  useEffect(() => {
    makeRequest();
  }, [fromDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <DashboardComponentApiRequestStateWrapper
      apiRequestState={getRequest.state}
      retry={makeRequest}
    >
      {(response, showLoadingOverlay) => (
        <InvestmentAllocationGraphComponent
          response={response}
          showLoadingOverlay={showLoadingOverlay}
        />
      )}
    </DashboardComponentApiRequestStateWrapper>
  );
};

type ComponentProps = {
  response: GetDataForInvestmentAllocationGraphResponse;
  showLoadingOverlay: boolean;
};

const InvestmentAllocationGraphComponent = ({ response, showLoadingOverlay }: ComponentProps) => {
  const { translate } = useInternationalisation();
  const PointFormat =
    '<span style="color:{point.color}">●</span>' +
    response.currencyCode +
    ' <b>{point.y:,.2f}</b><br />';
  const exportFileName = translate(
    'pages.dashboard.components.investmentAllocationGraph.exportName'
  );
  console.log(response);
  return response.shouldShowChart ? (
    <DashboardComponentLayout
      headerText={translate('pages.dashboard.components.investmentAllocationGraph.title')}
      showLoadingOverlay={showLoadingOverlay}
    >
      <PieChart
        exportFilename={exportFileName}
        dataPoints={response.dataPoints}
        tooltipOptions={{
          pointFormat: PointFormat,
        }}
      />
    </DashboardComponentLayout>
  ) : (
    <MessageContainer>
      {translate('pages.dashboard.components.investmentAllocationGraph.message')}
    </MessageContainer>
  );
};

type GetDataForInvestmentAllocationGraphQuery = {
  runDate: string;
};

export type GetDataForInvestmentAllocationGraphResponse = {
  currencyCode: string | null;
  shouldShowChart: boolean;
  dataPoints: Array<{
    value: number;
    label: string;
  }>;
};
