import { IsoDatestamp } from '../../helpers/dateTimeHelpers';

export type GetInvestorDetailsResponse = BaseCounterpartDetails &
  NonManagerOnlyInvestorDetails &
  ManagerOnlyInvestorDetails & { editable: boolean };

export type BaseCounterpartDetails = {
  contactPerson: string | null;
  phoneNumber1: string | null;
  phoneNumber2: string | null;
  mobileNumber: string | null;
  emailAddress: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  addressLine3: string | null;
  addressLine4: string | null;
  mailingAddressLine1: string | null;
  mailingAddressLine2: string | null;
  mailingAddressLine3: string | null;
  mailingAddressLine4: string | null;
  faxNumber: string | null;
  counterpartId: number;
  investorNumber: string | null;
  communicationPreferenceCode: CommunicationPreferenceCode;
  agent: string | null;
  agentWebAccess: string | null;
  name: string;
  alternativeName: string | null;
  blockedFields: Array<BlockedField>;
  preferredReportingCurrency: string | null;
};

export type NonManagerOnlyInvestorDetails = {
  taxNumber: string | null;
  usTaxNumber: string | null;
  companyNumber: string | null;
  businessNumber: string | null;
  fatcaStatus: string | null;
  crsStatus: string | null;
  showFatcaCrsStatus: boolean;
};

export type ManagerOnlyInvestorDetails = {
  dobDateStamp: IsoDatestamp | null;
  webAccess: string | null;
  webUserName: string | null;
  agentId: number | null;
  dobDisplay: boolean | null;
};

export const communicationPreferenceCodes = ['N', 'E', 'L', 'F', 'W'] as const;

export type CommunicationPreferenceCode = typeof communicationPreferenceCodes[number];

export const communicationPreferencesByCode: Record<CommunicationPreferenceCode, string> = {
  N: 'None',
  E: 'Email',
  L: 'Letter',
  F: 'Fax',
  W: 'Web',
};

export const blockedFieldCodesByName = {
  ContactPerson: 'RCP',
  Address: 'A',
  MailingAddress: 'MA',
  TelephoneNumber1: 'T1',
  TelephoneNumber2: 'T2',
  Fax: 'FN',
  TaxNumber: 'TN',
  UsTaxNumber: 'UTN',
  BusinessNumber: 'BN',
  CompanyNumber: 'CN',
  CommunicationPreference: 'CP',
  EmailAddress: 'EM',
  MobilePhoneNumber: 'MN',
  Currency: 'CCY',
} as const;

export type BlockedField = {
  code: string;
};
