import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import { FieldContainer } from '../../../infrastructure/forms/common/FormField';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { SingleSelectField } from '../../../infrastructure/forms/fields/SingleSelectField';
import { SelectOptions } from '../../../infrastructure/interface/forms/BaseSelect';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import {
  BlockedField,
  blockedFieldCodesByName,
  CommunicationPreferenceCode,
  communicationPreferenceCodes,
  communicationPreferencesByCode,
} from '../GetInvestorDetailsResponse';

type Props = {
  blockedFields: Array<BlockedField>;
};

export const EditInvestorDetailsFormFields = (props: Props) => {
  const { translate } = useInternationalisation();
  const getFieldLabel = (translationKey: string) =>
    translate(`pages.investorDetailsPage.counterpartDetails.fieldNames.${translationKey}`);

  const communicationPreferenceSelectOptions: SelectOptions<CommunicationPreferenceCode> =
    communicationPreferenceCodes.map((code) => ({
      value: code,
      label: communicationPreferencesByCode[code],
    }));

  return (
    <>
      <InputField
        fieldName="contactPerson"
        label={getFieldLabel('contactPerson')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.ContactPerson;
        })}
      />
      <InputField
        fieldName="phoneNumber1"
        label={getFieldLabel('phoneNumber1')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.TelephoneNumber1;
        })}
      />
      <InputField
        fieldName="phoneNumber2"
        label={getFieldLabel('phoneNumber2')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.TelephoneNumber2;
        })}
      />
      <InputField
        fieldName="mobileNumber"
        label={getFieldLabel('mobileNumber')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.MobilePhoneNumber;
        })}
      />
      <InputField
        fieldName="emailAddress"
        label={getFieldLabel('email')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.EmailAddress;
        })}
      />
      <InputField
        fieldName="faxNumber"
        label={getFieldLabel('fax')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.Fax;
        })}
      />
      <FieldContainer>
        <FieldLabel>{getFieldLabel('registeredAddress')}</FieldLabel>
        <InputField
          fieldName="addressLine1"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.Address;
          })}
        />
        <InputField
          fieldName="addressLine2"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.Address;
          })}
        />
        <InputField
          fieldName="addressLine3"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.Address;
          })}
        />
        <InputField
          fieldName="addressLine4"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.Address;
          })}
        />
      </FieldContainer>
      <FieldContainer>
        <FieldLabel>{getFieldLabel('mailingAddress')}</FieldLabel>
        <InputField
          fieldName="mailingAddressLine1"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.MailingAddress;
          })}
        />
        <InputField
          fieldName="mailingAddressLine2"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.MailingAddress;
          })}
        />
        <InputField
          fieldName="mailingAddressLine3"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.MailingAddress;
          })}
        />
        <InputField
          fieldName="mailingAddressLine4"
          readOnly={props.blockedFields.some((field) => {
            return field.code === blockedFieldCodesByName.MailingAddress;
          })}
        />
      </FieldContainer>
      <InputField
        fieldName="companyNumber"
        label={getFieldLabel('companyNumber')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.CompanyNumber;
        })}
      />
      <InputField
        fieldName="businessNumber"
        label={getFieldLabel('businessNumber')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.BusinessNumber;
        })}
      />
      <InputField
        fieldName="taxNumber"
        label={getFieldLabel('taxNumber')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.TaxNumber;
        })}
      />
      <InputField
        fieldName="usTaxNumber"
        label={getFieldLabel('usTaxNumber')}
        readOnly={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.UsTaxNumber;
        })}
      />
      <SingleSelectField
        fieldName="communicationPreferenceCode"
        label={getFieldLabel('commsPreference')}
        options={communicationPreferenceSelectOptions}
        disabled={props.blockedFields.some((field) => {
          return field.code === blockedFieldCodesByName.CommunicationPreference;
        })}
      />
    </>
  );
};
