import { createContext } from 'react';
import { GetDataForTransactionsResponse, TransactionsFilterState } from './types';
import {
  getIsoDatestampDaysIntoThePast,
  getIsoDatestampToday,
  IsoDatestamp,
} from '../../helpers/dateTimeHelpers';
import { defaultInitialResultsPerPageOption } from '../../infrastructure/interface/tables/PaginatedTable';

const throwNotInitialisedError = () => {
  throw new Error('Transactions Page Context is not yet initialised');
};

export const TransactionsContext = createContext<TransactionsContextValue>({
  pageNumber: 1,
  setPageNumber: throwNotInitialisedError,
  resultsPerPage: defaultInitialResultsPerPageOption,
  setResultsPerPage: throwNotInitialisedError,
  response: null,
  setResponse: throwNotInitialisedError,
  inProgress: false,
  setInProgress: throwNotInitialisedError,
  investorId: null,
  setInvestorId: throwNotInitialisedError,
  companyId: null,
  setCompanyId: throwNotInitialisedError,
  equityAttributionId: null,
  setEquityAttributionId: throwNotInitialisedError,
  startDate: null,
  setStartDate: throwNotInitialisedError,
  endDate: null,
  setEndDate: throwNotInitialisedError,
  transactionTypes: [],
  setTransactionTypes: throwNotInitialisedError,
  agentId: null,
  setAgentId: throwNotInitialisedError,
  dealerId: null,
  setDealerId: throwNotInitialisedError,
  filterLoadError: null,
  setFilterLoadError: throwNotInitialisedError,
  unapprovedOnly: null,
  setUnapprovedOnly: throwNotInitialisedError,
  resetFilters: throwNotInitialisedError,
  loadData: throwNotInitialisedError,
});

type TransactionsContextValue = {
  pageNumber: number;
  setPageNumber(pageNumber: number): void;
  resultsPerPage: number;
  setResultsPerPage(resultsPerPage: number): void;
  response: GetDataForTransactionsResponse | null;
  setResponse(holdingsResponse: GetDataForTransactionsResponse): void;
  inProgress: boolean;
  setInProgress(inProgress: boolean): void;
  investorId: number | null;
  setInvestorId(investorId: number | null): void;
  companyId: number | null;
  setCompanyId(companyId: number | null): void;
  equityAttributionId: number | null;
  setEquityAttributionId(equityAttributionId: number | null): void;
  startDate: string | null;
  setStartDate(startDate: IsoDatestamp | null): void;
  endDate: string | null;
  setEndDate(endDate: IsoDatestamp | null): void;
  transactionTypes: Array<string | null>;
  setTransactionTypes(transactionTypes: Array<string | null>): void;
  agentId: number | null;
  setAgentId(agentId: number | null): void;
  dealerId: number | null;
  setDealerId(dealerId: number | null): void;
  unapprovedOnly: boolean | null;
  setUnapprovedOnly(unapprovedOnly: boolean | null): void;
  resetFilters(): void;
  filterLoadError: string | null;
  setFilterLoadError(filterLoadError: string | null): void;
  loadData: () => void;
};

export const defaultTransactionsFilterState: TransactionsFilterState = {
  pageNumber: 1,
  resultsPerPage: defaultInitialResultsPerPageOption,
  investorId: null,
  unapprovedOnly: null,
  startDate: null,
  endDate: null,
  transactionTypes: [],
  companyId: null,
  equityAttributionId: null,
  agentId: null,
  dealerId: null,
};
