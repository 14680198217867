import React, { useState, ForwardedRef, useRef, useEffect } from 'react';
import mergeRefs from 'react-merge-refs';
import ReactDatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components/macro';
import {
  backgroundColours,
  borderColours,
  colourBlack,
  colourGrey01,
  colourGrey02,
  colourPrimary03,
  colourPrimary05,
  colourPrimary08,
  colourWhite,
  textColours,
} from '../../../styling/design/colours';
import { fontFamily, fontWeightBold } from '../../../styling/design/fonts';
import { Input } from './Input';
import { shadow1 } from '../../../styling/design/shadows';
import { IconButton } from '../buttons/IconButton';
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from '../../../icons/icons';
import { spacing4, spacing48, spacing8 } from '../../../styling/design/spacing';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { FormatDateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import {
  IsoDatestamp,
  toDateFromIsoDatestamp,
  toIsoDatestampFromDate,
} from '../../../helpers/dateTimeHelpers';

export const datePickerTestId = 'datepicker';
export const datePickerInputTestId = 'datepicker-input';

type DatePickerProps = {
  'data-testid'?: string;
  value: IsoDatestamp | null;
  placeholder?: string;
  onChange: (date: IsoDatestamp | null) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  minDate?: IsoDatestamp;
  maxDate?: IsoDatestamp;
  name?: string;
  disabled?: boolean;
  clearable?: boolean;
  highlight?: boolean;
  customInput?: React.ReactNode;
};

const DatePickerComponent = (
  props: DatePickerProps,
  forwardedRef?: ForwardedRef<ReactDatePicker>
) => {
  const {
    value,
    placeholder,
    onChange,
    onBlur,
    minDate,
    maxDate,
    name,
    disabled,
    clearable,
    highlight,
    customInput,
  } = props;
  const { formatDate, getLocale, translate } = useInternationalisation();
  const localRef = useRef<ReactDatePicker>(null);

  const valueAsDate = value != null ? toDateFromIsoDatestamp(value) : null;
  const [selectedDate, setSelectedDate] = useState(valueAsDate);
  const minDateAsDate = minDate != null ? toDateFromIsoDatestamp(minDate) : null;
  const maxDateAsDate = maxDate != null ? toDateFromIsoDatestamp(maxDate) : null;

  useEffect(() => {
    if (maxDateAsDate) {
      setSelectedDate(maxDateAsDate);
      onChange(toIsoDatestampFromDate(maxDateAsDate));
    }
  }, [
    //only fire if maxDate value changes.
    maxDate,
  ]);

  const onContainerClick = (event: React.MouseEvent<HTMLDivElement>) => {
    // Prevents an issue where the datepicker will not close on select when wrapped in another component
    event.preventDefault();
  };

  const openCalendar = () => {
    localRef.current?.setOpen(true);
  };

  const onCalendarIconClick = () => {
    openCalendar();
  };

  const [mode, setMode] = useState<'day' | 'month' | 'year'>('day');
  const suppressModeReset = useRef(false);

  const onChangeWrapper = (newDate: Date | null) => {
    setSelectedDate(newDate);
    //Only change the date if a user specifically chooses the day to reduce sproc calls.
    if (mode === 'day') {
      const newValue = newDate != null ? toIsoDatestampFromDate(newDate) : null;
      onChange(newValue);
    }

    if (mode === 'month') {
      suppressModeReset.current = true;
      setMode('day');
      setTimeout(() => openCalendar(), 0);
    } else if (mode === 'year') {
      suppressModeReset.current = true;
      setMode('month');
      setTimeout(() => openCalendar(), 0);
    }
  };

  return (
    <DatePickerContainer
      data-testid={props['data-testid'] ?? datePickerTestId}
      onClick={onContainerClick}
    >
      <ReactDatePicker
        ref={mergeRefs(forwardedRef != null ? [localRef, forwardedRef] : [localRef])}
        selected={selectedDate}
        placeholderText={placeholder ?? translate('form.datePicker.defaultPlaceholder')}
        onChange={onChangeWrapper}
        onBlur={onBlur}
        minDate={minDateAsDate}
        maxDate={maxDateAsDate}
        name={name}
        disabled={disabled}
        isClearable={clearable}
        enableTabLoop={false}
        customInput={
          customInput != null ? (
            customInput
          ) : (
            <Input
              icon={{ icon: <CalendarIcon />, onClick: onCalendarIconClick }}
              data-testid={datePickerInputTestId}
              highlight={highlight}
            />
          )
        }
        renderCustomHeader={(options) => {
          const customHeaderProps = {
            options,
            formatDate,
            onClickMonthName: () => setMode('month'),
            onClickYearName: () => setMode('year'),
          };

          return mode === 'year' ? (
            <YearModeHeader {...customHeaderProps} />
          ) : mode === 'month' ? (
            <MonthModeHeader {...customHeaderProps} />
          ) : (
            <DayModeHeader {...customHeaderProps} />
          );
        }}
        showPopperArrow={false}
        locale={getLocale()}
        strictParsing
        dateFormat="P" // Use the locale date format - https://date-fns.org/v2.0.0-beta.2/docs/format
        showMonthYearPicker={mode === 'month'}
        showYearPicker={mode === 'year'}
        onCalendarClose={() => {
          if (suppressModeReset.current) {
            suppressModeReset.current = false;
          } else {
            setMode('day');
          }
        }}
      />
    </DatePickerContainer>
  );
};

export const DatePicker = React.forwardRef<ReactDatePicker, DatePickerProps>(DatePickerComponent);

type HeaderOptions = ReactDatePickerCustomHeaderProps;

type CustomHeaderProps = {
  options: HeaderOptions;
  formatDate: FormatDateFunction;
  onClickMonthName: () => void;
  onClickYearName: () => void;
};

const DayModeHeader = ({ options, formatDate, onClickMonthName }: CustomHeaderProps) => (
  <HeaderContainer>
    <NavButton
      icon={<ChevronLeftIcon />}
      onClick={options.decreaseMonth}
      disabled={options.prevMonthButtonDisabled}
    />
    <HeaderDateContainer onClick={onClickMonthName}>
      {formatDate(options.date.toString(), { month: 'long', year: 'numeric' })}
    </HeaderDateContainer>
    <NavButton
      icon={<ChevronRightIcon />}
      onClick={options.increaseMonth}
      disabled={options.nextMonthButtonDisabled}
    />
  </HeaderContainer>
);

const MonthModeHeader = ({ options, onClickYearName }: CustomHeaderProps) => (
  <HeaderContainer marginBottom="0">
    <NavButton
      icon={<ChevronLeftIcon />}
      onClick={options.decreaseYear}
      disabled={options.prevYearButtonDisabled}
    />
    <HeaderDateContainer onClick={onClickYearName}>
      {options.date.getFullYear()}
    </HeaderDateContainer>
    <NavButton
      icon={<ChevronRightIcon />}
      onClick={options.increaseYear}
      disabled={options.nextYearButtonDisabled}
    />
  </HeaderContainer>
);

const YearModeHeader = ({ options }: CustomHeaderProps) => (
  <HeaderContainer marginBottom="0">
    <NavButton
      icon={<ChevronLeftIcon />}
      onClick={() => options.changeYear(options.date.getFullYear() - 12)}
    />
    <HeaderDateContainer>&nbsp;</HeaderDateContainer>
    <NavButton
      icon={<ChevronRightIcon />}
      onClick={() => options.changeYear(options.date.getFullYear() + 12)}
    />
  </HeaderContainer>
);

const HeaderContainer = styled.div<{ marginBottom?: string }>`
  margin: 0 ${spacing4} ${(props) => props.marginBottom ?? spacing8} ${spacing4};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavButton = styled(IconButton).attrs(() => ({ buttonStyle: 'ghost', size: 'small' }))`
  color: ${colourPrimary05};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    color: ${colourPrimary08};
    background-color: transparent;
    border-color: transparent;
  }

  svg {
    height: 10px;
    width: 10px;
  }
`;

const HeaderDateContainer = styled.div`
  margin: 0 ${spacing8};
  color: ${textColours.default};
  cursor: pointer;
  opacity: 1;
  transition: opacity 0.75s ease;
  font-weight: ${fontWeightBold};

  :hover {
    opacity: 0.75;
  }
`;

const DatePickerContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker {
    font-family: ${fontFamily};
    border: 1px solid ${borderColours.default};
    box-shadow: ${shadow1};

    * {
      outline: none;
    }

    &__input-container {
      width: 100%;
    }

    &__close-icon {
      padding-right: 0;
      margin-right: ${spacing48};

      &::after {
        background-color: ${colourGrey01};
        font-weight: ${fontWeightBold};
        color: ${colourBlack};

        transition: background-color 0.25s ease;
      }

      &:hover {
        &::after {
          background-color: ${colourGrey02};
        }
      }
    }

    &__header {
      background-color: ${colourGrey01};
      border-radius: 5px 5px 0 0;
      border: none;
    }

    &__day-names {
      background-color: ${backgroundColours.default};
      padding-top: ${spacing4};
    }

    &__day-name,
    &__month-text {
      margin: ${spacing4};
      color: ${textColours.default};
      font-weight: ${fontWeightBold};
    }

    &__month {
      margin-top: 0;
      background-color: ${backgroundColours.default};
      border-radius: 0 0 5px 5px;
      border: 1px solid ${colourWhite};
    }

    &__month-text {
      width: 74px;
      padding: ${spacing8};
    }

    &__day {
      margin: ${spacing4};
      color: ${textColours.default};
      border-radius: 5px !important;
      transition: background-color 0.125s ease, color 0.125s ease;

      &--today {
        background-color: ${colourGrey01};
        color: ${textColours.default};
      }

      &--outside-month {
        visibility: hidden;
      }

      &--keyboard-selected {
        &:focus {
          background-color: ${colourPrimary03};
          color: ${colourWhite};
        }
      }

      &--disabled {
        opacity: 0.5;
        cursor: not-allowed;

        &::after {
          opacity: 0;
        }

        &:hover {
          background-color: transparent;
        }
      }

      &--selected {
        background-color: ${backgroundColours.selected};
        color: ${textColours.selected};
      }

      &:hover:not(&--disabled) {
        background-color: ${backgroundColours.defaultHover};
        color: ${textColours.default};
      }
    }

    .react-datepicker__month-wrapper {
      .react-datepicker__month {
        margin: ${spacing4};
        color: ${textColours.default};
        border-radius: 5px !important;
        transition: background-color 0.125s ease, color 0.125s ease;

        &--keyboard-selected {
          &:focus {
            background-color: ${colourPrimary03};
            color: ${colourWhite};
          }
        }

        &--disabled {
          opacity: 0.5;
          cursor: not-allowed;

          &::after {
            opacity: 0;
          }

          &:hover {
            background-color: transparent;
          }
        }

        &--selected {
          background-color: ${backgroundColours.selected};
          color: ${textColours.selected};
        }

        &:hover:not(&--disabled) {
          background-color: ${backgroundColours.defaultHover};
          color: ${textColours.default};
        }
      }
    }

    .react-datepicker__year-wrapper {
      max-width: 248px;

      .react-datepicker__year-text {
        margin: ${spacing4};
        color: ${textColours.default};
        border-radius: 5px !important;
        transition: background-color 0.125s ease, color 0.125s ease;
        padding: 8px;
        width: auto;
        flex: 1;

        &--keyboard-selected {
          &:focus {
            background-color: ${colourPrimary03};
            color: ${colourWhite};
          }
        }

        &--disabled {
          opacity: 0.5;
          cursor: not-allowed;

          &::after {
            opacity: 0;
          }

          &:hover {
            background-color: transparent;
          }
        }

        &--selected {
          background-color: ${backgroundColours.selected};
          color: ${textColours.selected};
        }

        &:hover:not(&--disabled) {
          background-color: ${backgroundColours.defaultHover};
          color: ${textColours.default};
        }
      }
    }
  }
`;
