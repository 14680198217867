import React, { ForwardedRef, useMemo, useState } from 'react';
import { Validator } from 'fluentvalidation-ts';
import { Formik, FormikHelpers, FormikProps } from 'formik';
import styled from 'styled-components/macro';
import { PlusIcon } from '../../../icons/icons';
import { Form } from '../../../infrastructure/forms/common/Form';
import { InputField } from '../../../infrastructure/forms/fields/InputField';
import { ButtonWithIcon } from '../../../infrastructure/interface/buttons/ButtonWithIcon';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { TranslateFunction } from '../../../internationalisation/types/InternationalisationContextValue';
import { spacing16 } from '../../../styling/design/spacing';
import { AccessGroupSelectField } from '../../metadata/accessGroup/AccessGroupSelectField';
import { notBeEmpty } from '../../validation/stringValidation';
import { FieldLabel } from '../../../infrastructure/forms/common/FieldLabel';
import { LoadingOverlay } from '../../../infrastructure/interface/components/LoadingOverlay';
import { defaultAccessGroupSelectSettings } from '../../metadata/accessGroup/AccessGroupSelect';
import {
  DashboardComponentColumnIndex,
  DashboardComponentHeight,
  DashboardComponentResponse,
  DashboardComponentWidth,
} from '../GetDashboardsForCurrentUserResponse';
import {
  addMultipleComponentsToBottomOfDashboard,
  DashboardLayoutEditor,
} from '../DashboardLayoutEditor';
import { DashboardComponentsModal } from '../DashboardComponentsModal';
import { DashboardLoginRoleSelectField } from './DashboardLoginRoleSelectField';
import { roleCodeCharactersByRoleName } from '../../authentication/UserRole';

type Props = {
  onSubmit: (
    formModel: CreateEditDashboardFormModel,
    formikHelpers: FormikHelpers<CreateEditDashboardFormModel>
  ) => void;
  initialValues: CreateEditDashboardFormModel;
  onInnerComponentApiError: (error: string) => void;
  error: string | null;
};

const CreateEditDashboardFormComponent = (
  props: Props,
  forwardedRef: ForwardedRef<FormikProps<CreateEditDashboardFormModel>>
) => {
  const { translate } = useInternationalisation();
  const [addComponentModalIsOpen, setAddComponentModalIsOpen] = useState(false);

  const validator = useMemo(() => new CreateEditDashboardFormValidator(translate), [translate]);

  return (
    <Formik<CreateEditDashboardFormModel>
      onSubmit={props.onSubmit}
      initialValues={props.initialValues}
      validate={validator.validate}
      innerRef={forwardedRef}
    >
      {(formikProps) => {
        const setComponents = (value: Array<DashboardComponentResponse>) =>
          formikProps.setFieldValue('components', value);

        return (
          <>
            {props.error && (
              <Alert
                alertType="negative"
                header={translate('errors.apology')}
                isDismissible={true}
                withMarginBottom={true}
              >
                {props.error}
              </Alert>
            )}
            <LoadingOverlay showOverlay={formikProps.isSubmitting}>
              <Form>
                <FieldRowOuterContainer>
                  <FieldRowInnerContainer>
                    <InputField
                      fieldName="name"
                      label={translate('pages.createDashboard.fieldLabels.name')}
                    />
                    <DashboardLoginRoleSelectField />
                    <AccessGroupSelectField
                      fieldName="accessGroupId"
                      label={translate('pages.createDashboard.fieldLabels.accessGroup')}
                      onError={props.onInnerComponentApiError}
                      settings={{ ...defaultAccessGroupSelectSettings, includeBlank: true }}
                    />
                    <div>
                      <FieldLabel>{'\u00A0'}</FieldLabel>
                      <ButtonWithIcon
                        icon={<PlusIcon />}
                        onClick={() => setAddComponentModalIsOpen(true)}
                        data-testid={addComponentButtonTestId}
                      >
                        {translate('pages.createDashboard.addComponentButtonText')}
                      </ButtonWithIcon>
                    </div>
                  </FieldRowInnerContainer>
                </FieldRowOuterContainer>
                <DashboardComponentsModal
                  isOpen={addComponentModalIsOpen}
                  onRequestClose={() => setAddComponentModalIsOpen(false)}
                  onAddComponents={(selectedComponents) =>
                    addMultipleComponentsToBottomOfDashboard(
                      selectedComponents,
                      formikProps.values.components,
                      setComponents
                    )
                  }
                  onError={props.onInnerComponentApiError}
                  componentIdsToIgnore={formikProps.values.components.map(
                    (c) => c.dashboardComponentId
                  )}
                  dashboardLoginRole={formikProps.values.loginRole}
                />
                <DashboardLayoutEditor
                  components={formikProps.values.components}
                  setComponents={setComponents}
                  error={formikProps.getFieldMeta('components').error ?? null}
                  onAddComponentButtonClick={() => setAddComponentModalIsOpen(true)}
                />
              </Form>
            </LoadingOverlay>
          </>
        );
      }}
    </Formik>
  );
};

export const CreateEditDashboardForm = React.forwardRef<
  FormikProps<CreateEditDashboardFormModel>,
  Props
>(CreateEditDashboardFormComponent);

class CreateEditDashboardFormValidator extends Validator<CreateEditDashboardFormModel> {
  constructor(translate: TranslateFunction) {
    super();

    this.ruleFor('name').must(notBeEmpty(translate));
    this.ruleFor('components').must({
      predicate: (value) => value.length !== 0,
      message: translate('pages.createDashboard.fieldErrors.noComponents'),
    });
  }
}

export const addComponentButtonTestId = 'add-component-button';

export const mapCreateEditDashboardFormModelToBaseCommand = (
  formModel: CreateEditDashboardFormModel
): BaseCreateEditDashboardCommand => ({
  description: formModel.name,
  loginRole: formModel.loginRole,
  accessGroupId: formModel.accessGroupId!,
  components: formModel.components.map((component) => ({
    dashboardComponentId: component.dashboardComponentId,
    width: component.width,
    height: component.height,
    columnIndex: component.columnIndex,
    rowIndex: component.rowIndex,
  })),
});

export type CreateEditDashboardFormModel = {
  name: string;
  loginRole: DashboardLoginRole;
  accessGroupId: number | null;
  components: Array<DashboardComponentResponse>;
};

const dashboardLoginRoles = [
  roleCodeCharactersByRoleName.Manager,
  roleCodeCharactersByRoleName.Investor,
  roleCodeCharactersByRoleName.Advisor,
];

export type DashboardLoginRole = typeof dashboardLoginRoles[number];

export type BaseCreateEditDashboardCommand = {
  description: string;
  loginRole: DashboardLoginRole;
  accessGroupId: number;
  components: Array<DashboardComponentCommand>;
};

export type DashboardComponentCommand = {
  dashboardComponentId: number;
  width: DashboardComponentWidth;
  height: DashboardComponentHeight;
  columnIndex: DashboardComponentColumnIndex;
  rowIndex: number;
};

const FieldRowOuterContainer = styled.div`
  display: flex;
`;

const FieldRowInnerContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: column;
  grid-column-gap: ${spacing16};
`;
