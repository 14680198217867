export const readSessionStorage = (storageCode: SessionStorageCode) => {
  return sessionStorage.getItem(storageCode);
};

export const readSessionStorageNumber = (storageCode: SessionStorageCode): number | null => {
  const stringValue = readSessionStorage(storageCode);
  if (stringValue == null) return null;
  return Number(stringValue);
};

export const writeSessionStorage = (storageCode: SessionStorageCode, value: string) => {
  sessionStorage.setItem(storageCode, value);
};

export const clearSessionStorage = (storageCode?: SessionStorageCode) => {
  if (storageCode != null) {
    // a specific storageCode was provided only clear that and return.
    sessionStorage.removeItem(storageCode);
    return;
  }

  //check session storage for a GroupId or a SiteId as these should always persist if set.
  var groupId = sessionStorage.getItem(sessionStorageCodes.groupId);
  var siteId = sessionStorage.getItem(sessionStorageCodes.siteId);

  //no storage code was provided clear all session storage.
  sessionStorage.clear();

  //rewrite the GroupId and SiteId to session storage (if exists)
  if (groupId) sessionStorage.setItem(sessionStorageCodes.groupId, groupId);
  if (siteId) sessionStorage.setItem(sessionStorageCodes.siteId, siteId);
};

export const sessionStorageCodes = {
  companyId: 'companyId',
  signedIn: 'signedIn',
  groupId: 'groupId',
  siteId: 'siteId',
} as const;

export type SessionStorageCode = keyof typeof sessionStorageCodes;
