import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router';
import styled, { css } from 'styled-components/macro';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { RequestFailedAlert } from '../../../infrastructure/api/RequestFailedAlert';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { Alert } from '../../../infrastructure/interface/components/Alert';
import { LoadingOverlay } from '../../../infrastructure/interface/components/LoadingOverlay';
import { CentredSpinner } from '../../../infrastructure/interface/components/Spinner';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { fontSizes, fontWeightBold } from '../../../styling/design/fonts';
import { spacing32, spacing8 } from '../../../styling/design/spacing';
import { DesktopOnly } from '../../../styling/layout/DesktopOnly';
import { MobileOnly } from '../../../styling/layout/MobileOnly';
import { PaddedPage } from '../../../styling/layout/PaddedPage';
import { onDesktop, onMobile } from '../../../styling/layout/screenBreakpoints';
import { RequiresUserRole } from '../../authentication/UserRoles';
import { useGetInvestorDetailsCounterpartDropdownOptionsRequest } from '../GetInvestorDetailsCounterpartDropdownOptions';
import { InvestorDetailsCounterpartDropdown } from '../InvestorDetailsCounterpartDropdown';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';
import { DesktopDistributionReinvestmentsList } from './DesktopDistributionReinvestmentsList';
import {
  DistributionReinvestmentsContextProvider,
  useDistributionReinvestmentsContext,
} from './DistributionReinvestmentsContext';
import { MobileDistributionReinvestmentsList } from './MobileDistributionReinvestmentsList';
import { AuthenticationContext } from '../../authentication/AuthenticationContext';
import { PageHeaderActions } from '../../../infrastructure/interface/components/PageHeaderActions';
import { PlusIcon } from '../../../icons/icons';
import { AppLinkWithIcon } from '../../../infrastructure/interface/components/AppLink';

export const DistributionReinvestmentsLayout = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.distributionReinvestments.title'));

  const { getUser } = useContext(AuthenticationContext);
  const { permissions } = getUser();

  return (
    <RequiresUserRole userRole={['Investor', 'Consolidated Investor', 'Advisor']}>
      {permissions.canViewOwnDistributionReinvestments ? (
        <DistributionReinvestmentsContextProvider>
          <DistributionReinvestmentsLayoutApiRequestStateWrapper />
        </DistributionReinvestmentsContextProvider>
      ) : (
        <PaddedPage>
          <Alert alertType="negative" header={translate('errors.generic')}>
            {translate('pages.distributionReinvestments.accessError')}
          </Alert>
        </PaddedPage>
      )}
    </RequiresUserRole>
  );
};

const DistributionReinvestmentsLayoutApiRequestStateWrapper = () => {
  const { translate } = useInternationalisation();
  const navigate = useNavigate();
  const investorId = useInvestorIdUrlParam();

  const { permissionsResponse, deleteDistributionReinvestmentResponse } =
    useDistributionReinvestmentsContext();

  const counterpartDropdownOptionsRequest =
    useGetInvestorDetailsCounterpartDropdownOptionsRequest();

  const makeRequest = () => {
    counterpartDropdownOptionsRequest.makeRequest({
      queryParameters: {
        selectedCounterpartId: investorId,
        includeBeneficialOwners: false,
      },
      onSuccess: ({ options }) => {
        if (options.some((option) => option.counterpartId === investorId)) {
          return;
        } else if (options.length) {
          navigate(`/distribution-reinvestments/${options[0].counterpartId}`, { replace: true });
        }
      },
    });
  };

  useOnMount(() => makeRequest());

  const onCounterpartIdChange = (newCounterpartId: number | null) =>
    navigate(`/distribution-reinvestments/${newCounterpartId ?? ''}`);

  const createDistributionReinvestmentPath =
    '/distribution-reinvestments/' + (investorId == null ? '' : `${investorId}/`) + 'create';

  return (
    <ApiRequestStateWrapper
      apiRequestState={counterpartDropdownOptionsRequest.state}
      retry={makeRequest}
    >
      {(response) =>
        response.options.length === 0 ? (
          <PaddedPage>
            <Alert
              alertType="warning"
              header={translate('pages.distributionReinvestments.permissionDeniedTitle')}
            >
              {translate('pages.distributionReinvestments.permissionDeniedMessage')}
            </Alert>
          </PaddedPage>
        ) : (
          <PaddedPage>
            <HeaderContainer>
              <HeaderPrefix>
                {translate('pages.distributionReinvestments.headerPrefix')}
              </HeaderPrefix>
              <InvestorDetailsCounterpartDropdown
                options={response.options}
                onChange={onCounterpartIdChange}
                value={investorId}
              />
            </HeaderContainer>
            <PageHeaderActions withBackButton={true} withMarginTop={true}>
              {permissionsResponse?.canEditDistributionReinvestments === true && (
                <AppLinkWithIcon icon={<PlusIcon />} to={createDistributionReinvestmentPath}>
                  {translate('pages.distributionReinvestments.addButtonText')}
                </AppLinkWithIcon>
              )}
            </PageHeaderActions>
            {deleteDistributionReinvestmentResponse && (
              <Alert alertType="positive" withMarginBottom={true}>
                {deleteDistributionReinvestmentResponse.status === 'pendingApproval'
                  ? translate('pages.distributionReinvestments.deleteSuccessAlert.pendingApproval')
                  : translate(
                      'pages.distributionReinvestments.deleteSuccessAlert.modifiedSuccessfully'
                    )}
              </Alert>
            )}
            <Outlet />
          </PaddedPage>
        )
      }
    </ApiRequestStateWrapper>
  );
};

export const DistributionReinvestments = () => {
  const { translate } = useInternationalisation();

  const {
    distributionReinvestmentsRequestInProgress,
    latestDistributionReinvestmentsResponse,
    distributionReinvestmentsRequestError,
    makeDistributionReinvestmentsRequest,
    permissionsRequestError,
    makePermissionsRequest,
    permissionsResponse,
  } = useDistributionReinvestmentsContext();

  const initialRequestIsInProgress =
    latestDistributionReinvestmentsResponse == null && distributionReinvestmentsRequestInProgress;

  if (distributionReinvestmentsRequestError) {
    return (
      <RequestFailedAlert
        error={distributionReinvestmentsRequestError}
        retry={makeDistributionReinvestmentsRequest}
      />
    );
  }

  if (permissionsRequestError) {
    return <RequestFailedAlert error={permissionsRequestError} retry={makePermissionsRequest} />;
  }

  if (initialRequestIsInProgress) {
    return <CentredSpinner size="xlarge" />;
  }

  if (permissionsResponse?.canViewDistributionReinvestments === false) {
    return (
      <Alert
        alertType="warning"
        header={translate('pages.distributionReinvestments.permissionDeniedTitle')}
      >
        {translate('pages.distributionReinvestments.permissionDeniedMessage')}
      </Alert>
    );
  }

  if (latestDistributionReinvestmentsResponse == null) {
    return null;
  }

  if (latestDistributionReinvestmentsResponse.reinvestmentDefaultIsNo) {
    return (
      <LoadingOverlay showOverlay={distributionReinvestmentsRequestInProgress}>
        <Alert alertType="notice">
          <AlertContentContainer>
            <div>
              {translate('pages.distributionReinvestments.reinvestmentDefaultIsNoMessage1')}
            </div>
            <div>
              {translate('pages.distributionReinvestments.reinvestmentDefaultIsNoMessage2')}
            </div>
          </AlertContentContainer>
        </Alert>
      </LoadingOverlay>
    );
  }

  return (
    <>
      <DesktopOnly>
        <DesktopDistributionReinvestmentsList />
      </DesktopOnly>
      <MobileOnly>
        <MobileDistributionReinvestmentsList />
      </MobileOnly>
    </>
  );
};

const HeaderContainer = styled.div`
  display: flex;

  ${onMobile(css`
    flex-direction: column;
  `)}

  ${onDesktop(css`
    flex-direction: row;
    align-items: center;
  `)}
`;

const HeaderPrefix = styled.div`
  font-weight: ${fontWeightBold};
  white-space: nowrap;
  margin-right: ${spacing8};

  ${onMobile(css`
    font-size: ${fontSizes.large.mobile};
  `)}

  ${onDesktop(css`
    font-size: ${fontSizes.xxlarge.desktop};
  `)}
`;

const AlertContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing32};
`;
