import React from 'react';
import { PaddedPage } from '../../../styling/layout/PaddedPage';
import { RequiresUserRole } from '../../authentication/UserRoles';
import { investorUserRoles } from '../../authentication/UserRole';
import { ApiRequestStateWrapper } from '../../../infrastructure/api/ApiRequestStateWrapper';
import { useOnMount } from '../../../infrastructure/hooks/useOnMount';
import { useInternationalisation } from '../../../internationalisation/hooks/useInternationalisation';
import { useInvestorIdUrlParam } from '../useInvestorIdUrlParam';
import { Outlet, useNavigate } from 'react-router-dom';
import { useWindowTitle } from '../../../infrastructure/hooks/useWindowTitle';
import { InvestorBankingDetailsHeader } from './InvestorBankingDetailsHeader';
import { useGetInvestorDetailsCounterpartDropdownOptionsRequest } from '../GetInvestorDetailsCounterpartDropdownOptions';
import { InvestorBankingDetailsContextProvider } from './InvestorBankingDetailsContext';
import { Alert } from '../../../infrastructure/interface/components/Alert';

export const InvestorBankingDetailsLayout = () => {
  const { translate } = useInternationalisation();
  useWindowTitle(translate('pages.bankingDetails.title'));

  return (
    <RequiresUserRole userRole={investorUserRoles}>
      <InvestorBankingDetailsLayoutApiRequestStateWrapper />
    </RequiresUserRole>
  );
};

const InvestorBankingDetailsLayoutApiRequestStateWrapper = () => {
  const navigate = useNavigate();

  const { translate } = useInternationalisation();

  const investorId = useInvestorIdUrlParam();

  const counterpartDropdownOptionsRequest =
    useGetInvestorDetailsCounterpartDropdownOptionsRequest();

  const makeCounterpartDropdownOptionsRequest = () => {
    counterpartDropdownOptionsRequest.makeRequest({
      queryParameters: {
        selectedCounterpartId: null,
        includeBeneficialOwners: false,
      },
      onSuccess: ({ options }) => {
        if (options.some((option) => option.counterpartId === investorId)) {
          return;
        } else if (options.length) {
          navigate(`/banking-details/${options[0].counterpartId}`, { replace: true });
        }
      },
    });
  };

  useOnMount(() => makeCounterpartDropdownOptionsRequest());

  const onInvestorIdChange = (newInvestorId: number | null) =>
    navigate(`/banking-details/${newInvestorId ?? ''}`);

  return (
    <ApiRequestStateWrapper
      apiRequestState={counterpartDropdownOptionsRequest.state}
      retry={makeCounterpartDropdownOptionsRequest}
    >
      {(response) =>
        response.options.length === 0 ? (
          <PaddedPage>
            <Alert
              alertType="warning"
              header={translate('pages.bankingDetails.permissionDeniedTitle')}
            >
              {translate('pages.bankingDetails.permissionDeniedMessage')}
            </Alert>
          </PaddedPage>
        ) : (
          <InvestorBankingDetailsContextProvider>
            <PaddedPage>
              <InvestorBankingDetailsHeader
                investorOptions={response.options}
                onInvestorIdChange={onInvestorIdChange}
              />
              <Outlet />
            </PaddedPage>
          </InvestorBankingDetailsContextProvider>
        )
      }
    </ApiRequestStateWrapper>
  );
};
